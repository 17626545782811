import React, { memo } from "react";
import { Button } from "reactstrap";

const CertifiedScreen = ({ handleNext }) => {
    return (
        <>
            <div>
                <h1 className="stepper-header">You are certified! 🎉</h1>
                <div className="stepper-desc">
                    <p className="stepper-desc-sub-header">
                        Congratulations, your connection has been certified.
                    </p>
                    <p>
                        Now it’s time to move forward with Production
                        Certification in our live environment. This process
                        ensures the available properties in the Hotel Trader
                        network are fully mapped in your system and allows you
                        to complete and audit test bookings.
                    </p>
                    <p>
                        You will find all the data you need here on this screen
                        and when you’re ready, click through to our live test
                        booking app.
                    </p>
                </div>
            </div>
            <Button
                type="submit"
                className="stepper-submit-btn mt-5"
                size="lg"
                onClick={handleNext}
            >
                Lets go !
            </Button>
        </>
    );
};

export default memo(CertifiedScreen);
