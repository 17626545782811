import React from "react";

import "./questionnaire.scss";
import { Button } from "reactstrap";
import SandboxInstructionsMappingFiles from "./SandboxInstructionsMappingFiles";

const SandboxCertiInstructionsQuestionnaire = ({
    onSubmit,
    hotelId,
    authToken,
    generateToken,
    mappingFilesPrefix,
}) => {
    return (
        <div id="sandbox-certi-instructions" className="grey font-13">
            <div className="quest">
                <p className="instruction-header font-weight-bold">
                    Our fully automated Certification App allows you to finalize
                    integration on your own schedule
                </p>
                <p>
                    Congratulations! You are at the final stage of integration
                    to the Hotel Trader API’s! This process is critical to
                    ensure an optimized connection and may reveal some errors in
                    previous steps in the process.
                </p>
                <p>
                    The tabs above represent each of the API call types we
                    support.  The tabs available to you are intended to reflect
                    the answers you gave in your questionnaire at Step 1. 
                    Errors are always possible so if anything is out of
                    alignment, please review your answers here and then please
                    reach out to our support team, if necessary.
                </p>
                <ol>
                    <li>
                        Please call the appropriate endpoint for each scenario
                        according to the instructions on the page.
                    </li>
                    <li>
                        Record the response returned in your fully developed
                        front-end.
                    </li>
                    <li>
                        A script will run against the response to ensure
                        everything is being returned correctly
                    </li>
                    <li>
                        Please note the attributes flagged as mandatory, these
                        fields will need to be part of your integration to pass
                        certification.
                    </li>
                </ol>
                <p>A few general guidelines to be aware of:</p>
                <p className="instruction-sub-header font-weight-bold">
                    Searches
                </p>
                All searches should call: Hotel ID: {hotelId}
                <p className="instruction-sub-header font-weight-bold">
                    Bookings
                </p>
                <ol>
                    <li>
                        Please always book the cheapest room (unless otherwise
                        specified)
                    </li>
                    <li>
                        Please always use 2 adults occupancy (unless otherwise
                        specified)
                    </li>
                    <li>
                        When asked for an attribute value, please follow the
                        format of the example provided.
                    </li>
                    <li>
                        WE USE AN AUTOMATED SCRIPT TO VALIDATE THE ANSWERS. ANY
                        VARIANCE IN THE FORMAT WILL CAUSE DELAYS IN COMPLETION
                        OF THE CERTIFICATION.
                    </li>
                    <li>
                        As noted in the API documentation, HT considers all
                        modifications that alter the price of the booking to be
                        a CANCEL+REBOOK. Please ensure that the reservations in
                        your integration are priced accordingly.
                    </li>
                </ol>
                <SandboxInstructionsMappingFiles
                    authToken={authToken}
                    generateToken={generateToken}
                    mappingFilesPrefix={mappingFilesPrefix}
                />
                <div className="question-footer font-13 mt-5">
                    <Button
                        type="submit"
                        className="prev-ques submit-instructions-btn"
                        size="lg"
                        onClick={onSubmit}
                    >
                        Let's go
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default SandboxCertiInstructionsQuestionnaire;
