import React, { memo, useCallback, useMemo } from "react";
import "./question-detail.scss";
import {
    ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS,
    CLIENT_ACCOUNT_DETAILS_FORM,
    getTrimmedValue,
} from "../../../constants";

const SingleChoiceMultiOptionQuestion = ({
    id,
    value,
    updateAnswer,
    options,
    question,
    quesIndex,
    isSubmitted,
    questionnaireType,
    isWrongAnsSubmitted,
}) => {
    const getOptionItemClassName = useCallback(
        (option) => {
            let className =
                "grouping-question-ques-option-item single-choice-multi-option-ques-option-item";
            if (value === option?.name && value === "No") {
                className = `selected-single-choice-multi-option-ques-option-item-no ${className}`;
            } else if (value === option?.name) {
                className = `selected-single-choice-multi-option-ques-option-item ${className}`;
            }
            if (isSubmitted) {
                className = `${className} disabled-grouping-question-ques-option-item`;
            }
            return className;
        },
        [value, isSubmitted],
    );

    const getWrapperClassName = useMemo(() => {
        let className = "single-choice-question-box-options";
        if (questionnaireType !== CLIENT_ACCOUNT_DETAILS_FORM) {
            if (isSubmitted) {
                className = `${className} single-choice-question-box-options-submitted-wrapper`;
            }

            if (isWrongAnsSubmitted) {
                className = `${className} single-choice-question-box-options-error-wrapper`;
            }
        }
        return className;
    }, [questionnaireType, isSubmitted, isWrongAnsSubmitted]);

    const getOptions = useCallback(
        (options) => {
            return (
                <>
                    {options?.map((option) => (
                        <div
                            className={getOptionItemClassName(option)}
                            key={`multi-option-single-choice-${id}-${getTrimmedValue(
                                option.name,
                            )}`}
                            onClick={() =>
                                !isSubmitted && updateAnswer(id, option.name)
                            }
                        >
                            {option?.name}
                        </div>
                    ))}
                </>
            );
        },
        [getOptionItemClassName, id, isSubmitted, updateAnswer],
    );

    return (
        <div className="single-choice-multi-option-ques-wrapper">
            {questionnaireType !== ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[2] &&
            questionnaireType !== ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[3] ? (
                <div className="single-choice-question-box">
                    <div className="single-choice-question-ques-name">
                        <div>{question}</div>
                    </div>
                    <div className={getWrapperClassName}>
                        {getOptions(options)}
                        <i className="fa fa-exclamation-circle" />
                        <i className="fa fa-check-circle" />
                    </div>
                </div>
            ) : (
                <div className="grouping-question-box">
                    <div className="grouping-question-ques-name">
                        <div>{quesIndex}.</div>
                        <div>{question}</div>
                    </div>
                    {getOptions(options)}
                </div>
            )}
        </div>
    );
};

export default memo(SingleChoiceMultiOptionQuestion);
