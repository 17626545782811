import { Col, Row } from "reactstrap";
import {
    ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS,
    CLIENT_ACCOUNT_DETAILS_FORM,
    getFormattedDate,
} from "../../../constants";
import React from "react";
import { Link } from "react-router-dom";

const HEADER_DETAILS = {
    CLIENT_API: {
        header: "API Questionnaire",
        desc:
            "This questionnaire is an investment for success and allows us to " +
            "guide you through the integration in the manner that most aligns with " +
            "your current infrastructure. Given the wide variance in technical " +
            "architecture between systems, a commitment here will yield optimal " +
            "results.",
    },
    PROD_TESTING: {
        header: "Production Certification",
        desc: "Making sure all the data is mapped correctly in the live environment.",
    },
    NEW_CLIENT_FORM: {
        header: "Client Account Details",
        desc:
            "Questions for clients to answer on their test " +
            "reservation and production environment testing details.",
    },
};

const DateDetails = ({ createdDate, lastUpdatedDate }) => {
    return (
        <div className="font-12 text-end questionnaire-date-details">
            <span>
                <span className="date-name">Start date :</span>
                {getFormattedDate(createdDate, "dS mmm yyyy")}
                &nbsp;&nbsp;
            </span>
            <span>
                <span className="date-name">Last saved :</span>
                {getFormattedDate(lastUpdatedDate, "dS mmm yyyy")}
                &nbsp;
            </span>
        </div>
    );
};

const QuestionnaireHeaderDetails = ({
    createdDate,
    lastUpdatedDate,
    headerDetails: { header, desc },
}) => {
    return (
        <div className={"mb-5"}>
            <div className="questionnaire-name header-color">{header}</div>

            <Row>
                <Col lg={8}>
                    <p className="font-12 questionnaire-desc">{desc}</p>
                    {header === "API Questionnaire" && (
                        <p className="font-12 questionnaire-desc">
                            If you need a point of reference, you can find our
                            developer docs
                            <Link to="/docs" className="api-docs footer-links">
                                <span> here</span>
                            </Link>
                        </p>
                    )}
                </Col>
                <Col lg={4}>
                    <DateDetails
                        createdDate={createdDate}
                        lastUpdatedDate={lastUpdatedDate}
                    />
                </Col>
            </Row>
        </div>
    );
};

const SandboxQuestionnaireHeader = ({ createdDate, lastUpdatedDate }) => {
    return (
        <div className={"mb-5"}>
            <div className="questionnaire-name header-color">
                <div className="bookmark-wrapper">
                    <div className="bookmark" />
                </div>
                <div>
                    <div>Sandbox Certification App</div>
                    <div className="questionnaire-desc">
                        Questions for clients to answer on their system
                        architecture & how they search suppliers.
                    </div>
                </div>
            </div>
            <div>
                <DateDetails
                    createdDate={createdDate}
                    lastUpdatedDate={lastUpdatedDate}
                />
            </div>
        </div>
    );
};

const QuestionnaireHeader = ({
    questionnaireType,
    createdDate,
    lastUpdatedDate,
}) => {
    switch (questionnaireType) {
        case ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[2]:
        case ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[3]:
            return (
                <QuestionnaireHeaderDetails
                    createdDate={createdDate}
                    lastUpdatedDate={lastUpdatedDate}
                    headerDetails={HEADER_DETAILS["CLIENT_API"]}
                />
            );
        case ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[5]:
            return (
                <SandboxQuestionnaireHeader
                    createdDate={createdDate}
                    lastUpdatedDate={lastUpdatedDate}
                />
            );
        case ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[10]:
        case ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[11]:
            return (
                <QuestionnaireHeaderDetails
                    createdDate={createdDate}
                    lastUpdatedDate={lastUpdatedDate}
                    headerDetails={HEADER_DETAILS["PROD_TESTING"]}
                />
            );
        case CLIENT_ACCOUNT_DETAILS_FORM:
            return (
                <QuestionnaireHeaderDetails
                    createdDate={createdDate}
                    lastUpdatedDate={lastUpdatedDate}
                    headerDetails={HEADER_DETAILS["NEW_CLIENT_FORM"]}
                />
            );
        default:
            return null;
    }
};

export default QuestionnaireHeader;
