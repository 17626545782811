import { Outlet } from "react-router-dom";
import React, { memo, useCallback, useState } from "react";
import Header from "../components/header/Header";
import SideNav from "../components/sidenav/SideNav";
import Footer from "../components/footer/Footer";
import { OBScreensProvider } from "../contexts/OBScreensContext";
import Loader from "../components/Loader/Loader";
import Toastr from "../components/Toastr/Toastr";

const MemoizedMainComponent = React.memo(({ toggleSidebar, isOpen }) => {
    return (
        <OBScreensProvider>
            <Header toggleSidebar={toggleSidebar} />
            <div className="main-container">
                <SideNav isOpen={isOpen} />
                <main className={isOpen ? "content expanded" : "content"}>
                    <Outlet />
                    <div className="content-overlay"></div>
                </main>
            </div>
            <Footer />
            <Loader />
            <Toastr />
        </OBScreensProvider>
    );
});

const RootLayout = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleSidebar = useCallback(() => {
        setIsOpen((prev) => !prev);
    }, [setIsOpen]);

    return (
        <div className="App">
            <MemoizedMainComponent
                toggleSidebar={toggleSidebar}
                isOpen={isOpen}
            />
        </div>
    );
};

export default memo(RootLayout);
