import React, { useState } from "react";
import CustomPopUpWindow from "../../components/custom-popup-modal/CustomPopupModal";
import { Button, FormGroup, Input } from "reactstrap";
import MultiValueInput from "../../components/multi-value-input/MultiValueInput";
import { validateEmail } from "../../constants";

const AddTeam = ({ showAddTeam, showOrHideModal }) => {
    const [emails, setEmails] = useState([]);
    const [isValidEmails, setIsValidEmails] = useState(true);

    const handleFormFieldChange = ({ key, value }) => {
        console.log(emails);
        let selectedEmails = [];
        if (value) {
            setIsValidEmails(true);
            value.split(",").forEach((val) => {
                if (!validateEmail(val)) {
                    setIsValidEmails(false);
                    selectedEmails = [];
                    return;
                }
            });
            setEmails(selectedEmails);
        }
    };

    const renderBody = () => (
        <div className="add-team-modal">
            <p className="invite-header">Invite members with email</p>
            <p className="invite-desc">
                Please enter your team mates work email and choose their role
                before sending an invite.
            </p>
            <div className="add-team-member-email">
                <MultiValueInput
                    classNamePrefix="add-team-member-email-input"
                    onChange={(_value) =>
                        handleFormFieldChange({
                            key: "memberEmail",
                            value: _value,
                        })
                    }
                />
            </div>
            {!isValidEmails && (
                <p className="add-team-member-error-message">
                    Please Enter valid emails
                </p>
            )}
            <div className="add-team-member-type">
                <FormGroup check>
                    <div>
                        <Input type="checkbox" name="addTeamMemberType" />
                        Developer
                    </div>
                    <div>
                        <Input type="checkbox" name="addTeamMemberType" />
                        Finance
                    </div>
                    <div>
                        <Input type="checkbox" name="addTeamMemberType" />
                        Operations
                    </div>
                    <div>
                        <Input type="checkbox" name="addTeamMemberType" />
                        Admin
                    </div>
                </FormGroup>
            </div>
        </div>
    );

    return (
        <CustomPopUpWindow
            className={"add-team-member-modal"}
            toggle={() => showOrHideModal(false)}
            isOpen={showAddTeam}
            hideHeader={true}
            closeButtonText="Cancel"
            footerClassName={"add-team-member-footer"}
            body={renderBody()}
            extraButtons={
                <Button className="add-team-invite-btn mL-10">Invite</Button>
            }
        />
    );
};

export default AddTeam;
