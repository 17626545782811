import { Button, Col, Row } from "reactstrap";
import "./index.scss";
import React, {
    memo,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import ScenarioGroupingList from "../cert-components/ScenarioGroupingList";
import GroupQuestions from "../questions/GroupingQuestions/GroupQuestions";
import { GROUPED_MULTI_OPTION } from "../questions/ques-data";
import { OBScreensContext } from "../../contexts/OBScreensContext";
import {
    ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS,
    CLIENT_ACCOUNT_DETAILS_FORM,
    compareArrays,
    getTrimmedValue,
    INSTRUCTIONS_SCENARIO_NAME,
    QUESTIONNAIRE_TYPES,
} from "../../constants";
import { toast } from "react-toastify";
import {
    getQuestionnaire,
    autoSaveQuestionnaire,
    submitScenario,
    getAuthToken,
    generateAuthToken,
    getPullClientsMetadataFilesUrlPrefix,
} from "../../api";
import ScenarioGrid from "../questions/scenario-grid/ScenarioGrid";
import QuestionnaireHeader from "../questions/GroupingQuestions/QuestionnaireHeader";
import SandboxCertiInstructionsQuestionnaire from "./questionnaire/SandboxCertiInstructionQuestionnaire";
import { InfinitySpin } from "react-loader-spinner";

const Questionnaire = ({ questionnaireType }) => {
    const { setIsLoading, getDashboardStep } = useContext(OBScreensContext);
    const [selectedScenario, setSelectedScenario] = useState({});
    const [selectedGroup, setSelectedGroup] = useState("");

    //contains answers of all groups in same objects
    //separated groupedAnswers to reduce re-rendering of GroupedQuestions component
    const [groupedAnswers, setGroupedAnswers] = useState({});
    const [otherAnswers, setOtherAnswers] = useState({});
    // contains {scenarioId: {questionId: answers}}
    const [allOriginalAnswers, setAllOriginalAnswers] = useState({});

    const [allQuestions, setAllQuestions] = useState([]);

    //sets all the answers which are not approved in this object,
    // contains {scenarioId: {groupId: [questionId]}}
    const [scenarioStatus, setScenarioStatus] = useState({});
    //checks if a scenario is submitted at-least once, contains {scenarioId: true}
    const [scenarioSubmittedStatus, setSubmittedScenarioStatus] = useState({});

    const [scenarioGrouping, setScenarioGrouping] = useState({});

    const [authToken, setAuthToken] = useState("");
    const [mappingFilesPrefix, setMappingFilesPrefix] = useState("");

    const getQuestionsToUpdateRef = useRef();
    const questionnaireWrapper = useRef();

    useEffect(() => {
        if (questionnaireType === ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[5]) {
            const requestBody = {
                env: "sandbox",
            };
            setIsLoading(true);
            Promise.all([
                getAuthToken(requestBody),
                getPullClientsMetadataFilesUrlPrefix(requestBody),
            ]).then(
                ([res1, res2]) => {
                    setIsLoading(false);
                    setAuthToken(res1?.data);
                    setMappingFilesPrefix(res2?.data);
                },
                () => {
                    setIsLoading(false);
                    toast.error("Failed to get token");
                },
            );
        }
    }, [setIsLoading, questionnaireType]);

    const generateToken = useCallback(() => {
        setIsLoading(true);
        generateAuthToken({
            env: "sandbox",
        }).then(
            (res) => {
                setAuthToken(res?.data);
                setIsLoading(false);
            },
            () => {
                setIsLoading(false);
                toast.error("Failed to generate token");
            },
        );
    }, [setIsLoading]);

    //true means scenario is submitted successfully and it shouldn't be editable
    const isScenarioDisabled = useCallback(
        (scenarioId) => {
            if (
                questionnaireType !==
                    ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[2] &&
                questionnaireType !== ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[3]
            ) {
                return (
                    scenarioSubmittedStatus[scenarioId] &&
                    !scenarioStatus[scenarioId]
                );
            } else if (
                questionnaireType === ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[3]
            ) {
                return !!scenarioGrouping?.scenarios?.some((sc) =>
                    sc?.scenarioGroups?.some((gQ) =>
                        gQ?.groupQuestions?.some((q) => q.answerApproved),
                    ),
                );
            }
        },
        [
            questionnaireType,
            scenarioStatus,
            scenarioSubmittedStatus,
            scenarioGrouping,
        ],
    );

    const separateGroupedQuestions = useCallback(
        (questions, groupId, scenarioId) => {
            return questions
                ? questions.reduce(
                      (a, c, index) => {
                          const quesIndex = index + 1;
                          c.type === GROUPED_MULTI_OPTION
                              ? a.groupedQues.push({
                                    ...c,
                                    quesIndex,
                                    groupId,
                                    scenarioId,
                                })
                              : a.questions.push({
                                    ...c,
                                    quesIndex,
                                    groupId,
                                    scenarioId,
                                });
                          a.allQuesIds.push(c.questionId);
                          return a;
                      },
                      { groupedQues: [], questions: [], allQuesIds: [] },
                  )
                : { groupedQues: [], questions: [], allQuesIds: [] };
        },
        [],
    );

    //adds another question object into groups which has grouped questions and other questions seperated
    const updateQuestionsWithSeparatedQues = useCallback(
        (scenarios) => {
            return scenarios?.reduce((acc, s) => {
                const scenarioGroups = s.scenarioGroups.reduce(
                    (groupAcc, g) => {
                        const group = { ...g };
                        group.separatedQues = separateGroupedQuestions(
                            g.groupQuestions,
                            g.groupId,
                            s.scenarioId,
                        );
                        return [...groupAcc, group];
                    },
                    [],
                );

                return [...acc, { ...s, scenarioGroups }];
            }, []);
        },
        [separateGroupedQuestions],
    );

    //returns all the questions and answers for which answers are changed
    const getQuestionsWithAnswersToUpdate = useMemo(() => {
        const scenarioId = selectedScenario?.scenarioId;
        const answers = {
            ...groupedAnswers,
            ...otherAnswers,
        };
        const updatedQues = [];
        const allScenarioAnswers = allOriginalAnswers[scenarioId] ?? {};
        Object.keys(allScenarioAnswers).forEach((quesId) => {
            const currentAns = answers[quesId];
            const originalAns = allScenarioAnswers[quesId];
            if (currentAns && Array.isArray(currentAns)) {
                if (!compareArrays(originalAns, currentAns)) {
                    updatedQues.push({
                        questionId: quesId,
                        answer: currentAns.join(";"),
                    });
                }
            } else if (currentAns !== originalAns) {
                updatedQues.push({
                    questionId: quesId,
                    answer: currentAns,
                });
            }
        });
        const quesAns = {
            questions: updatedQues,
            scenarioId: scenarioId,
        };
        return quesAns;
    }, [
        groupedAnswers,
        otherAnswers,
        allOriginalAnswers,
        selectedScenario?.scenarioId,
    ]);

    const getQuestionnaireData = useCallback(
        (type = "autoSave") => {
            if (type !== "autoSave") {
                setIsLoading(true);
            }
            getQuestionnaire(QUESTIONNAIRE_TYPES[questionnaireType]).then(
                (res) => {
                    const scenarioGrouping = res?.data;
                    if (
                        questionnaireType ===
                            ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[5] &&
                        scenarioGrouping?.scenarios?.length
                    ) {
                        scenarioGrouping?.scenarios?.splice(0, 0, {
                            scenarioId: INSTRUCTIONS_SCENARIO_NAME,
                            scenarioName: INSTRUCTIONS_SCENARIO_NAME,
                            scenarioGroups: [{ groupName: "" }],
                        });
                    }
                    setScenarioGrouping(scenarioGrouping);
                    const allUpdatedQues = updateQuestionsWithSeparatedQues(
                        scenarioGrouping.scenarios,
                    );
                    setAllQuestions(allUpdatedQues);
                    if (type !== "autoSave") {
                        const scenarios = scenarioGrouping?.scenarios;
                        if (scenarios.length > 0) {
                            setSelectedScenario(scenarios[0]);
                            setSelectedGroup(
                                scenarios[0].scenarioGroups[0].groupName,
                            );
                        }
                    }
                    setIsLoading(false);
                },
                () => {
                    toast.error("Unable to load data");
                    setIsLoading(false);
                },
            );
        },
        [questionnaireType, updateQuestionsWithSeparatedQues, setIsLoading],
    );

    useEffect(() => {
        getQuestionnaireData("onLoad");
    }, [getQuestionnaireData]);

    const submitQuestionnaireStep = useCallback(
        (updatedQuesObj) => {
            if (questionnaireType === CLIENT_ACCOUNT_DETAILS_FORM) {
                getQuestionnaireData();
                return;
            }

            submitScenario({
                scenarioId: updatedQuesObj?.scenarioId,
                questionnaireId: scenarioGrouping?.questionnaireId,
                questionnaireType: scenarioGrouping?.questionnaireType,
            }).then(
                (res) => {
                    if (
                        res?.data?.erroredQuestions?.length === 0 &&
                        res?.data?.status
                    ) {
                        toast.success("Successfully submitted");
                        getDashboardStep();
                        getQuestionnaireData();
                    } else if (res?.data?.erroredQuestions?.length !== 0) {
                        toast.warn(
                            `Validation failed for few answers ${
                                questionnaireType !==
                                ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[3]
                                    ? " in scenario: " +
                                      selectedScenario?.scenarioName
                                    : ""
                            }`,
                        );
                        getQuestionnaireData();
                    } else {
                        toast.warn(res?.data?.message);
                        getDashboardStep();
                        getQuestionnaireData();
                    }
                    setIsLoading(false);
                },
                () => {
                    toast.error("Failed to submit");
                    setIsLoading(false);
                },
            );
        },
        [
            setIsLoading,
            getDashboardStep,
            questionnaireType,
            getQuestionnaireData,
            scenarioGrouping?.questionnaireId,
            scenarioGrouping?.questionnaireType,
            selectedScenario?.scenarioName,
        ],
    );

    const autoSaveAnswers = useCallback(
        (updatedQuesObj, type) => {
            if (isScenarioDisabled(updatedQuesObj?.scenarioId)) return;
            if (updatedQuesObj?.questions?.length > 0) {
                setIsLoading(true);
                autoSaveQuestionnaire(updatedQuesObj).then(
                    (res) => {
                        if (questionnaireType === CLIENT_ACCOUNT_DETAILS_FORM) {
                            toast.success("Successfully submitted");
                        }
                        if (type === "SUBMIT") {
                            submitQuestionnaireStep(updatedQuesObj);
                        } else {
                            getQuestionnaireData();
                        }
                    },
                    () => {
                        toast.error("Unable to update");
                        setIsLoading(false);
                    },
                );
            } else if (type === "SUBMIT") {
                setIsLoading(true);
                if (questionnaireType === CLIENT_ACCOUNT_DETAILS_FORM) {
                    toast.success("Successfully submitted");
                }
                submitQuestionnaireStep(updatedQuesObj);
            }
        },
        [
            setIsLoading,
            submitQuestionnaireStep,
            getQuestionnaireData,
            isScenarioDisabled,
            questionnaireType,
        ],
    );

    const updateScenarioStatus = useCallback(
        (
            a,
            scenarioId,
            groupId,
            questionId,
            answerApproved,
            answerForceCorrect,
            submittedBy,
        ) => {
            //answerApproved will be true if answer is valid
            if (
                answerApproved === false &&
                !answerForceCorrect &&
                submittedBy
            ) {
                if (!a.scenarioStatus[scenarioId])
                    a.scenarioStatus[scenarioId] = { [groupId]: [] };
                if (!a.scenarioStatus[scenarioId][groupId])
                    a.scenarioStatus[scenarioId][groupId] = [];
                a.scenarioStatus[scenarioId][groupId].push(questionId);
                a.scenarioSubmittedStatus[scenarioId] = true;
            } else if (answerApproved !== null && submittedBy) {
                a.scenarioSubmittedStatus[scenarioId] = true;
            }
        },
        [],
    );

    const getAllAnswersAndScenarioStatus = useCallback(
        (allGroupQuestions) => {
            return allGroupQuestions.reduce(
                (
                    a,
                    {
                        clientAnswer,
                        type,
                        questionId,
                        scenarioId,
                        answerApproved,
                        answerForceCorrect,
                        submittedBy,
                        groupId,
                    },
                ) => {
                    const answer =
                        clientAnswer || clientAnswer === 0
                            ? clientAnswer
                            : null;
                    if (!a.allOriginalAnswers[scenarioId])
                        a.allOriginalAnswers[scenarioId] = {};
                    if (type === GROUPED_MULTI_OPTION) {
                        if (!a.groupedAnswers[questionId]) {
                            a.groupedAnswers[questionId] = [];
                            a.allOriginalAnswers[scenarioId][questionId] = [];
                        }
                        const ans = answer ? answer.split(";") : [];
                        a.groupedAnswers[questionId].push(...ans);
                        a.allOriginalAnswers[scenarioId][questionId].push(
                            ...ans,
                        );
                    } else {
                        a.answers[questionId] = answer;
                        a.allOriginalAnswers[scenarioId][questionId] = answer;
                    }

                    updateScenarioStatus(
                        a,
                        scenarioId,
                        groupId,
                        questionId,
                        answerApproved,
                        answerForceCorrect,
                        submittedBy,
                    );
                    return a;
                },
                {
                    groupedAnswers: {},
                    answers: {},
                    allOriginalAnswers: {},
                    scenarioStatus: {},
                    scenarioSubmittedStatus: {},
                },
            );
        },
        [updateScenarioStatus],
    );

    useEffect(() => {
        const {
            groupedAnswers,
            answers,
            allOriginalAnswers,
            scenarioStatus,
            scenarioSubmittedStatus,
        } = getAllAnswersAndScenarioStatus(
            allQuestions
                ?.flatMap((c) => c.scenarioGroups ?? [])
                .flatMap((c) => [
                    ...c.separatedQues?.groupedQues,
                    ...c.separatedQues?.questions,
                ]) ?? [],
        );
        setScenarioStatus(scenarioStatus);
        setSubmittedScenarioStatus(scenarioSubmittedStatus);
        setGroupedAnswers(groupedAnswers ?? {});
        setOtherAnswers(answers ?? {});
        setAllOriginalAnswers(allOriginalAnswers ?? {});
    }, [getAllAnswersAndScenarioStatus, allQuestions]);

    const updateAnswers = useCallback((answerType) => {
        if (answerType === "GROUPED_QUES") {
            return setGroupedAnswers;
        } else {
            return setOtherAnswers;
        }
    }, []);

    const invalidQuestionnaireAnswers = useCallback(
        (scenarioId) => {
            const answers = {
                ...groupedAnswers,
                ...otherAnswers,
            };
            const scenarioAns = allOriginalAnswers[scenarioId];
            // other than api_questionnaire, remaining questionnaires can be partially submitted
            if (
                questionnaireType !==
                    ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[2] &&
                questionnaireType !== ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[3]
            ) {
                return false;
            }
            return Object.keys(scenarioAns).some((id) => {
                return (
                    !answers[id] ||
                    (Array.isArray(answers[id]) && answers[id].length === 0)
                );
            });
        },
        [groupedAnswers, otherAnswers, allOriginalAnswers, questionnaireType],
    );

    const submitQuestionnaire = useCallback(() => {
        if (!invalidQuestionnaireAnswers(selectedScenario?.scenarioId)) {
            autoSaveAnswers(getQuestionsWithAnswersToUpdate, "SUBMIT");
        } else {
            toast.error(
                "Please complete all questions in each section before you submit!",
            );
        }
    }, [
        invalidQuestionnaireAnswers,
        autoSaveAnswers,
        getQuestionsWithAnswersToUpdate,
        selectedScenario?.scenarioId,
    ]);

    const getGroupsBySelScenario = useMemo(() => {
        const selectedScenarioObj = allQuestions?.find(
            (s) => s?.scenarioName === selectedScenario?.scenarioName,
        );
        return selectedScenarioObj?.scenarioGroups ?? [];
    }, [allQuestions, selectedScenario]);

    const getGroupBySelScenarioAndGroup = useMemo(() => {
        return getGroupsBySelScenario?.find(
            (g) => g.groupName === selectedGroup,
        );
    }, [selectedGroup, getGroupsBySelScenario]);

    useEffect(() => {
        getQuestionsToUpdateRef.current = getQuestionsWithAnswersToUpdate;
    }, [getQuestionsWithAnswersToUpdate]);

    const updateSelectedGroup = useCallback(
        (selectedGroup) => {
            autoSaveAnswers(getQuestionsWithAnswersToUpdate);
            setSelectedGroup(selectedGroup);
            questionnaireWrapper.current.scrollIntoView();
        },
        [autoSaveAnswers, getQuestionsWithAnswersToUpdate],
    );

    const updateScenario = useCallback(
        (scenario) => {
            autoSaveAnswers(getQuestionsWithAnswersToUpdate);
            setSelectedScenario(scenario);

            if (scenario?.scenarioGroups?.length > 0) {
                updateSelectedGroup(scenario?.scenarioGroups[0]?.groupName);
            }
        },
        [updateSelectedGroup, autoSaveAnswers, getQuestionsWithAnswersToUpdate],
    );

    //runs when moving out of the page
    useEffect(() => {
        return () => {
            autoSaveAnswers(getQuestionsToUpdateRef.current);
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const showScenarioGrid = useMemo(
        () =>
            questionnaireType === ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[5] ||
            questionnaireType === ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[10] ||
            questionnaireType === ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[11],
        [questionnaireType],
    );

    return (
        <>
            {scenarioGrouping?.scenarios?.length ? (
                <div className="tab-content" ref={questionnaireWrapper}>
                    <QuestionnaireHeader
                        questionnaireType={questionnaireType}
                        createdDate={scenarioGrouping?.createdDate}
                        lastUpdatedDate={scenarioGrouping?.lastUpdatedDate}
                    />
                    <Row>
                        {showScenarioGrid && (
                            <Col sm={12}>
                                <ScenarioGrid
                                    scenarios={scenarioGrouping?.scenarios}
                                    activeScenario={selectedScenario}
                                    scenarioStatus={scenarioStatus}
                                    scenarioSubmittedStatus={
                                        scenarioSubmittedStatus
                                    }
                                    updateScenario={updateScenario}
                                    questionnaireType={questionnaireType}
                                />
                            </Col>
                        )}

                        {selectedScenario?.scenarioName ===
                        INSTRUCTIONS_SCENARIO_NAME ? (
                            <Col lg={12}>
                                <SandboxCertiInstructionsQuestionnaire
                                    onSubmit={() => {
                                        updateScenario(
                                            scenarioGrouping?.scenarios
                                                ?.length > 1
                                                ? scenarioGrouping?.scenarios[1]
                                                : scenarioGrouping
                                                      ?.scenarios[0],
                                        );
                                    }}
                                    hotelId={scenarioGrouping?.hotelId}
                                    authToken={authToken}
                                    generateToken={generateToken}
                                    mappingFilesPrefix={mappingFilesPrefix}
                                />
                            </Col>
                        ) : (
                            <>
                                <div className="questionnaire-questions-wrapper">
                                    <div className="questionnaire-group-wrapper">
                                        {selectedGroup && (
                                            <ScenarioGroupingList
                                                selectedGroup={selectedGroup}
                                                selectedScenarioStatus={
                                                    scenarioStatus[
                                                        selectedScenario
                                                            ?.scenarioId
                                                    ] ?? {}
                                                }
                                                selectedScenarioSubmittedStatus={
                                                    scenarioSubmittedStatus[
                                                        selectedScenario
                                                            ?.scenarioId
                                                    ]
                                                }
                                                updateSelectedGroup={
                                                    updateSelectedGroup
                                                }
                                                allQuestionsByScenario={
                                                    getGroupsBySelScenario
                                                }
                                                allAnswers={{
                                                    ...groupedAnswers,
                                                    ...otherAnswers,
                                                }}
                                                questionnaireType={
                                                    questionnaireType
                                                }
                                            />
                                        )}
                                    </div>
                                    <div className="questionnaire-group-questions-wrapper">
                                        <GroupQuestions
                                            groupedAnswers={groupedAnswers}
                                            otherAnswers={otherAnswers}
                                            setAnswers={updateAnswers}
                                            group={
                                                getGroupBySelScenarioAndGroup
                                            }
                                            key={`group-questions-${getTrimmedValue(
                                                selectedGroup,
                                            )}`}
                                            questionnaireType={
                                                questionnaireType
                                            }
                                        />
                                        <div className="question-footer">
                                            {selectedScenario?.scenarioName !==
                                                INSTRUCTIONS_SCENARIO_NAME &&
                                                !isScenarioDisabled(
                                                    selectedScenario?.scenarioId,
                                                ) && (
                                                    <Button
                                                        type="submit"
                                                        className="submit-api-questionnaire"
                                                        size="lg"
                                                        onClick={
                                                            submitQuestionnaire
                                                        }
                                                    >
                                                        Submit
                                                    </Button>
                                                )}
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </Row>
                </div>
            ) : (
                <>
                    {scenarioGrouping?.scenarios?.length === 0 && (
                        <div className="tab-content font-18 questionnaire-empty-div">
                            Questionnaire is being generated, Please try again
                            latter
                            <InfinitySpin
                                width="200"
                                color="#ED1B2F"
                                secondaryColor="#ED1B2F"
                            />
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default memo(Questionnaire);
