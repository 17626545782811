import React, { useState } from "react";
import "./profile.scss";
import { Button, FormGroup, Input, Label } from "reactstrap";
import profileImg from "../../assets/img/profile.svg";
import { useNavigate } from "react-router-dom";

const Profile = () => {
    const navigate = useNavigate();

    const [isUserNameEdit, setIsUserNameEdit] = useState(false);
    const [isAddressEdit, setIsAddressEdit] = useState(false);
    const [name] = useState("Clayton Santos");
    const [address] = useState(
        "33A Arcadia Extension Station Long Name Road at the corner of nowhere, Nevada 430555, USA",
    );

    return (
        <div id="profile">
            <div className="profile-header">
                <h2>Profile</h2>
                <Button
                    type="submit"
                    className="full-btn"
                    size="lg"
                    onClick={() => navigate(-1)}
                >
                    back
                </Button>
            </div>

            <div className="profile-wrapper">
                <div className="box team-mates-box">
                    <p className="text-end team-mate-total">33 total</p>
                    <div className="team-mate-body">
                        <div className="team-mate-body-img">
                            <i className="fa fa-user" />
                        </div>
                        <div className="team-mate-body-desc">
                            <p>Team mates</p>
                            <p>Add & manage team members for different roles</p>
                        </div>
                    </div>
                    <div className="team-mate-footer">
                        <p>12 Operations</p>
                        <p>|</p>
                        <p>8 Developers</p>
                        <p>|</p>
                        <p>10 Finance</p>
                        <p>|</p>
                        <p>3 Admin</p>
                    </div>
                </div>
                <div className="box profile-details-box">
                    <div>
                        <img src={profileImg} alt="Profile" />
                    </div>
                    <div className="profile-details-text">
                        {!isUserNameEdit ? (
                            <>
                                <p className="profile-name">{name}</p>
                                <p className="profile-email">
                                    Ctonsantos@yahoo.com
                                </p>
                                <div>
                                    <Button
                                        type="submit"
                                        className="full-btn mt-5 mb-5"
                                        size="lg"
                                        onClick={() => setIsUserNameEdit(true)}
                                    >
                                        <i className="fa fa-pencil" />
                                        &nbsp;&nbsp;Edit
                                    </Button>
                                </div>
                            </>
                        ) : (
                            <>
                                <FormGroup>
                                    <Label for="username">
                                        <span className="username-label">
                                            Name{" "}
                                        </span>
                                    </Label>
                                    <Input
                                        id="username"
                                        name="username"
                                        className="username-input"
                                        type="text"
                                        defaultValue={name}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="email">
                                        <span className="username-label">
                                            Email{" "}
                                        </span>
                                    </Label>
                                    <Input
                                        id="email"
                                        name="email"
                                        className="username-input"
                                        type="text"
                                        value="Ctonsantos@yahoo.com"
                                        disabled={true}
                                    />
                                </FormGroup>
                                <div className="profile-name-edit-buttons">
                                    <Button
                                        type="submit"
                                        className="btn-primary save-btn"
                                        size="lg"
                                    >
                                        <i className="fa fa-check" />
                                        &nbsp;&nbsp;Save
                                    </Button>
                                    <Button
                                        type="submit"
                                        className="btn-primary mL-5"
                                        size="lg"
                                        onClick={() => setIsUserNameEdit(false)}
                                    >
                                        <i className="fa fa-close" />
                                        &nbsp;&nbsp;Cancel
                                    </Button>
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <div className="box address-box">
                    {!isAddressEdit ? (
                        <>
                            <h4>Address</h4>
                            <p>{address}</p>
                            <div>
                                <Button
                                    type="submit"
                                    className="full-btn mt-5 mb-5"
                                    size="lg"
                                    onClick={() => setIsAddressEdit(true)}
                                >
                                    <i className="fa fa-pencil" />
                                    &nbsp;&nbsp;Edit
                                </Button>
                            </div>
                        </>
                    ) : (
                        <>
                            <FormGroup>
                                <Label for="address">
                                    <span className="username-label">
                                        Address{" "}
                                    </span>
                                </Label>
                                <Input
                                    id="address"
                                    name="address"
                                    className="address-input"
                                    type="textarea"
                                    defaultValue={address}
                                />
                            </FormGroup>
                            <div className="profile-name-edit-buttons">
                                <Button
                                    type="submit"
                                    className="btn-primary save-btn"
                                    size="lg"
                                >
                                    <i className="fa fa-check" />
                                    &nbsp;&nbsp;Save
                                </Button>
                                <Button
                                    type="submit"
                                    className="btn-primary mL-5"
                                    size="lg"
                                    onClick={() => setIsAddressEdit(false)}
                                >
                                    <i className="fa fa-close" />
                                    &nbsp;&nbsp;Cancel
                                </Button>
                            </div>
                        </>
                    )}
                </div>
                <div className="box change-password-box">
                    <i className="fa fa-lock" />
                    &nbsp;&nbsp;Change Password
                    <i className="fa fa-chevron-down float-end" />
                </div>
            </div>
        </div>
    );
};

export default Profile;
