import logo from "../../assets/img/HTLogo.svg";
import "./header.css";
import { Link, useNavigate } from "react-router-dom";
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from "reactstrap";
import { memo, useContext, useState } from "react";
import { OBScreensContext } from "../../contexts/OBScreensContext";

const Header = ({ toggleSidebar }) => {
    const { isOBScreen } = useContext(OBScreensContext);
    const navigate = useNavigate();
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const navigateToDashboard = () => {
        navigate("/");
    };

    const navigateToDocs = () => {
        navigate("/docs");
    };

    const navigateToLogout = () => {
        navigate("/logout");
    };

    return (
        <header className={"header"}>
            {!isOBScreen && (
                <button className="menu-icon-btn" onClick={toggleSidebar}>
                    <i className="fa fa-bars font-22 menu-icon" />
                </button>
            )}
            <Link to="/">
                <img src={logo} className="logo" alt="Hoteltrader" />
            </Link>
            <button
                className="home-btn logout mR-10"
                onClick={navigateToDashboard}
            >
                <i className="fa fa-home font-22 menu-icon" />
            </button>
            <Dropdown
                className="dropdown-main mR-20"
                isOpen={dropdownOpen}
                toggle={() => setDropdownOpen(!dropdownOpen)}
            >
                <DropdownToggle>
                    <i className="fa fa-bars font-22 menu-icon" />
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem>
                        <div
                            className="dropdown-option"
                            onClick={navigateToDocs}
                        >
                            <i className="fa fa-file-text-o" />
                            Documentation
                        </div>
                    </DropdownItem>
                    <DropdownItem>
                        <div
                            className="dropdown-option"
                            onClick={navigateToLogout}
                        >
                            <i className="fa fa-sign-out" />
                            Sign Out
                        </div>
                    </DropdownItem>
                </DropdownMenu>
                <br />
            </Dropdown>
        </header>
    );
};

export default memo(Header);
