import React from "react";
import "./table.scss";

function TableContent({
    columns = ["Name", "Company", "City", "State"],
    data = [
        ["Joe James", "Test Corp", "Yonkers", "NY"],
        ["John Walsh", "Test Corp", "Hartford", "CT"],
        ["Bob Herm", "Test Corp", "Tampa", "FL"],
        ["James Houston", "Test Corp", "Dallas", "TX"],
    ],
    isDashboard = false,
}) {
    return <div className={"table-wrapper"}></div>;
}

export default TableContent;
