import React from "react";

import { Outlet, Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import rules from "./client-rules";

const check = (_rules, user, page, isAuthenticated) => {
    return !!isAuthenticated;
};

const UnauthorizedOrAuth = ({ user, location }) => {
    if (user) {
        return (
            <Navigate to="/unauthorized" state={{ from: location }} replace />
        );
    }
    return <Navigate to="/auth" state={{ from: location }} replace />;
};

const RequireAuth = ({ page }) => {
    const { idToken, user } = useAuth();
    const location = useLocation();
    return check(rules, user, page, idToken) ? (
        <Outlet />
    ) : (
        <UnauthorizedOrAuth user={user} location={location} />
    );
};

export default RequireAuth;
