import createAxiosInstance from "./axios";

let API_SERVER_PREFIX =
    process.env.REACT_APP_SERVER_DOMAIN +
    process.env.REACT_APP_SERVER_ENVIRONMENT;

const { getRequest, postRequest } = createAxiosInstance(API_SERVER_PREFIX);

export const getOBDashboardStep = () =>
    getRequest(`certification/get-onboarding-screen`);

export const updateOBDashboardStep = (request) =>
    postRequest(`certification/update-onboarding-screen`, request);

export const getQuestionnaire = (questionnaireType) =>
    getRequest(
        `certification/get-questions?questionnaireType=${questionnaireType}`,
    );

export const autoSaveQuestionnaire = (questionnaire) =>
    postRequest(`certification/api/auto-save-answer`, questionnaire);

export const submitScenario = (questionnaire) =>
    postRequest(`certification/api/submit-scenario`, questionnaire);

export const getAuthToken = (requestBody) =>
    postRequest(`certification/get-token`, requestBody);

export const generateAuthToken = (requestBody) =>
    postRequest(`certification/generate-token`, requestBody);

export const getPullClientsMetadataFilesUrlPrefix = (requestBody) =>
    postRequest(
        `certification/getPullClientsMetadataFilesUrlPrefix`,
        requestBody,
    );
export const getClientDocuments = () =>
    getRequest(`certification/getClientDocuments`);

export const getClientDocumentUrl = (fileName) =>
    getRequest(`certification/getClientDocumentUrl?fileName=${fileName}`);
