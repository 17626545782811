import React, { memo } from "react";
import { Button } from "reactstrap";
import logo from "../../../assets/img/HTLogo.svg";

const WelcomeStep = ({ onSubmit }) => {
    return (
        <>
            <div>
                <img src={logo} className="logo" alt="Hoteltrader" />
                <h1 className="stepper-header">You made it!</h1>
                <p className="stepper-desc">
                    Welcome to the Hotel Trader ecosystem and the future of
                    lodging distribution! We are super excited to have you as a
                    partner and are looking forward to our journey together. 
                    Now, let’s get you onboarded.
                </p>
                <p className="stepper-desc mt-4">
                    The following screens will guide you through the integration
                    process and make sure you have everything you need to go
                    live as quickly and effectively as possible
                </p>
            </div>
            <Button
                type="submit"
                className="begin-btn mt-5"
                size="lg"
                onClick={onSubmit}
            >
                Get Started
            </Button>
        </>
    );
};

export default memo(WelcomeStep);
