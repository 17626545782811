import { Button } from "reactstrap";
import React from "react";

export const APIQuestionnaireLaunchScreen = ({ handleNext }) => {
    return (
        <>
            <div>
                <h1 className="stepper-header">
                    First we need some details about your set-up
                </h1>
                <p className="stepper-desc-main mt-4">
                    Our experience is that the better prepared for success we
                    are, the more likely we are to be successful. The end result
                    is reaching ambitious performance targets and maximizing ROI
                    in our partnership.
                </p>
                <p className="stepper-desc mt-4">
                    To ensure the best possible outcome in terms of accuracy and
                    efficiency, we require some details about how you plan to
                    integrate to the API so we can tailor your certification and
                    help you optimize your connection.
                </p>
                <p className="stepper-desc mt-4">
                    This small investment up front helps create a smooth
                    integration experience and minimizes the risk of any issues
                    in the future.
                </p>
            </div>
            <Button
                type="submit"
                className="stepper-submit-btn mt-5"
                size="lg"
                onClick={handleNext}
            >
                Let's Do This
            </Button>
        </>
    );
};

export default APIQuestionnaireLaunchScreen;
