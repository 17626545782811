import React from "react";
import "./team.scss";
import logo from "../../assets/img/ht_logo.svg";

const TeamSummary = () => {
    return (
        <div className={"team-summary-wrapper mt-5"}>
            <div className={"team-summary-item team-logo-wrapper"}>
                <div className="team-logo">
                    <img src={logo} className="logo" alt="Hoteltrader" />
                </div>
            </div>
            <div className={"team-summary-item"}>
                <p className={"team-summary-header"}>Hopper team summary</p>
                <div className={"team-summary"}>
                    <div className={"individual-item"}>
                        <p className={"individual-item-header"}>
                            Total members
                        </p>
                        <div className={"individual-item-value"}>15</div>
                    </div>
                    <div className={"individual-item"}>
                        <p className={"individual-item-header"}>Active</p>
                        <div className={"individual-item-value"}>12</div>
                    </div>
                    <div className={"individual-item"}>
                        <p className={"individual-item-header"}>In-Active</p>
                        <div className={"individual-item-value"}>3</div>
                    </div>
                </div>
            </div>
            <div className={"team-summary-item team-summary-roles"}>
                <p className={"team-summary-header"}>Roles</p>
                <div className={"role-summary"}>
                    <div className={"individual-item"}>
                        <p className={"individual-item-header"}>Admins</p>
                        <div className={"individual-item-value"}>3</div>
                    </div>
                    <div className={"individual-item"}>
                        <p className={"individual-item-header"}>Finance</p>
                        <div className={"individual-item-value"}>3</div>
                    </div>
                    <div className={"individual-item"}>
                        <p className={"individual-item-header"}>Developers</p>
                        <div className={"individual-item-value"}>9</div>
                    </div>
                    <div className={"individual-item"}>
                        <p className={"individual-item-header"}>Developers</p>
                        <div className={"individual-item-value"}>5</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TeamSummary;
