import { Button, Col, Row } from "reactstrap";
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import "./documentation.scss";
import { useNavigate } from "react-router-dom";
import { STOPLIGHT_URL } from "../../constants";
import { getClientDocuments, getClientDocumentUrl } from "../../api";
import { OBScreensContext } from "../../contexts/OBScreensContext";
import { toast } from "react-toastify";
import { InfinitySpin } from "react-loader-spinner";

const DOC_FILES = [
    {
        lastUpdated: "Jan 1",
        headerName: "Platform configuration",
        text:
            "This document contains details specific to your Hotel Trader " +
            "connection through your third party technology platform",
        id: "PLATFORM_CONFIGURATION",
        apiFileName: "Platform-Configuration",
    },
    {
        lastUpdated: "Jan 1",
        headerName: "Operational guidelines",
        text:
            "This will walk you through our operational process and SLA's for" +
            "the most common pre-travel and on-spot-scenarios",
        id: "OPERATIONAL_GUIDELINES",
        apiFileName: "HT-Operations-Guidelines",
    },
    {
        lastUpdated: "Jan 1",
        headerName: "Company contacts and financial info",
        text:
            "This documents contains contact and financial information for " +
            "the Hotel Trade team",
        id: "COMPANY_CONTACTS",
        apiFileName: "HT-Contacts-Finance",
    },
    {
        lastUpdated: "Jan 1",
        headerName: "Invoice module training",
        text:
            "This is comprehensive guide to the invoice module in the client" +
            "portal (will have to be re-written for the new reskin)",
        id: "INVOICE_MODULE",
        apiFileName: "Invoice-Module-Training",
    },
    {
        lastUpdated: "Jan 1",
        headerName: "Support team contact details",
        text:
            "This document will provide you with all the contact details you " +
            "need to get in touch with our support teams",
        id: "SUPPORT_TEAM",
        apiFileName: "HT-Support-Contacts",
    },
    {
        lastUpdated: "Jan 1",
        headerName: "Distribution agreement",
        text: "This is a signed copy of our distribution agreement with Hotel Trader",
        id: "DISTRIBUTION_AGREEMENT",
        apiFileName: "Distribution-Agreement",
    },
    {
        lastUpdated: "Jan 1",
        headerName: "Developer portal",
        text:
            "This will take you to our developer portal where you can find " +
            "technical details of our APIs and descriptions of all the filed we use",
        link: STOPLIGHT_URL,
        id: "DEVELOPER_PORTAL",
        apiFileName: "",
    },
];

const DocFile = ({ headerName, text, link, apiFileName, documentConfig }) => {
    const { setIsLoading } = useContext(OBScreensContext);

    const formattedLastUpdated = useMemo(() => {
        if (!apiFileName) return "";
        const inputDateStr = documentConfig[apiFileName];
        const parts = inputDateStr.split(" ");
        const month = parts[1];
        const day = parts[2];
        const year = parts[parts.length - 1];
        return `${month} ${day}, ${year}`;
    }, [documentConfig, apiFileName]);

    const getDocUrl = useCallback(() => {
        if (link) {
            window.open(link, "_blank");
            return;
        }
        setIsLoading(true);
        getClientDocumentUrl(apiFileName).then(
            (res) => {
                setIsLoading(false);
                if (res.body) {
                    window.open(
                        res.body?.replace("https: ", "https:"),
                        "_blank",
                    );
                } else {
                    toast.error("Unable to load document");
                }
            },
            () => {
                setIsLoading(false);
                toast.error("Failed to load document");
            },
        );
    }, [setIsLoading, apiFileName, link]);

    return (
        <Col lg={4} sm={12}>
            <div className="doc-file-wrapper">
                <div
                    className={`doc-file-date ${
                        !apiFileName ? "invisible" : ""
                    }`}
                >
                    Updated {formattedLastUpdated}
                </div>
                <div className="doc-file-header">{headerName}</div>
                <div className="doc-file-text">{text}</div>
                <div>
                    <span
                        target="_blank"
                        rel="noreferrer"
                        className="download-btn"
                        onClick={getDocUrl}
                    >
                        View
                    </span>
                </div>
            </div>
        </Col>
    );
};

const DocumentationPage = () => {
    const navigate = useNavigate();
    const { setIsLoading, isLoading } = useContext(OBScreensContext);
    const [documentConfig, setDocumentConfig] = useState(null);

    useEffect(() => {
        setIsLoading(true);
        getClientDocuments().then(
            (res) => {
                setDocumentConfig(res.body);
                setIsLoading(false);
            },
            () => {
                setDocumentConfig({});
                setIsLoading(false);
            },
        );
    }, [setIsLoading]);

    return (
        <div className="documentation-page-wrapper">
            <div className="documentation-page-header">
                <div>
                    <div className="documentation-page-header-text">
                        Doc center
                    </div>
                    <div className="documentation-page-header-desc">
                        Access your API Docs, Certification help guide and any
                        other white paper that you need from here
                    </div>
                </div>
                <div className="documentation-page-buttons">
                    <Button
                        type="submit"
                        className="go-back-btn"
                        size="lg"
                        onClick={() => navigate(-1)}
                    >
                        Go Back
                    </Button>
                </div>
            </div>
            <div className="client-account-details-wrapper">
                <div className="client-account-details-desc">
                    <i className="fa fa-address-card-o" />
                    <div>
                        <div className="client-account-details-header-text">
                            Client Account Details !
                        </div>
                        <div className="client-account-details-header-sub-text">
                            Please fill in your client contact details before
                            you go live. There are no more questionnaires after
                            this. <b>We promise !</b>
                        </div>
                    </div>
                </div>
                <div className="client-account-details-buttons">
                    <Button
                        type="submit"
                        className="lets-go-btn"
                        size="lg"
                        onClick={() => navigate("/client-details")}
                    >
                        Let's go
                        <i className="fa fa-long-arrow-right" />
                    </Button>
                </div>
            </div>
            <Row className="docs-files-wrapper">
                {documentConfig ? (
                    <>
                        {DOC_FILES.filter(
                            (doc) =>
                                !doc.apiFileName ||
                                !!documentConfig[doc.apiFileName],
                        ).map((doc) => (
                            <DocFile
                                {...doc}
                                key={`doc-file-${doc.id}`}
                                documentConfig={documentConfig}
                            />
                        ))}
                    </>
                ) : (
                    !isLoading && (
                        <InfinitySpin
                            width="200"
                            color="#ED1B2F"
                            secondaryColor="#ED1B2F"
                        />
                    )
                )}
            </Row>
        </div>
    );
};

export default DocumentationPage;
