import "./dashboard.scss";
import React, { useContext } from "react";
import DashboardStepper from "./DashboardStepper";
import { QUESTIONNAIRE_SCREENS } from "../../constants";
import DashboardQuestionnaire from "./DashboardQuestionnaire";
import { OBScreensContext } from "../../contexts/OBScreensContext";

const DashboardStepperOrQuestionnaire = ({ onboardingScreen }) => {
    if (!QUESTIONNAIRE_SCREENS.includes(onboardingScreen)) {
        return <DashboardStepper />;
    }
    return <DashboardQuestionnaire questionnaireType={onboardingScreen} />;
};

const DashboardPage = () => {
    const {
        isOBScreen,
        clientOnboardingScreen: {
            onboardingScreen,
            id: clientOnboardingScreenId,
        },
    } = useContext(OBScreensContext);

    return (
        <>
            {clientOnboardingScreenId && (
                <>
                    {isOBScreen && (
                        <DashboardStepperOrQuestionnaire
                            onboardingScreen={onboardingScreen}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default DashboardPage;
