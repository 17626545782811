import "./question-type-grid.scss";
import React, { memo, useCallback } from "react";
import {
    ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS,
    getTrimmedValue,
    INSTRUCTIONS_SCENARIO_NAME,
} from "../../../constants";

const ScenarioGrid = ({
    scenarios,
    activeScenario,
    scenarioClassName = "",
    updateScenario,
    scenarioStatus,
    scenarioSubmittedStatus,
    questionnaireType,
}) => {
    const onScenarioChange = (value) => {
        if (updateScenario) updateScenario(value);
    };

    const getSubmittedScenarioClassName = useCallback(
        (scenario) => {
            if (scenario?.scenarioName === INSTRUCTIONS_SCENARIO_NAME) {
                return "success-scenario";
            }
            if (scenarioSubmittedStatus[scenario?.scenarioId]) {
                if (
                    Object.keys(scenarioStatus[scenario?.scenarioId] ?? [])
                        .length > 0
                ) {
                    return "error-scenario";
                } else {
                    return "success-scenario";
                }
            }
            return "";
        },
        [scenarioSubmittedStatus, scenarioStatus],
    );

    return (
        <>
            <div className="scenario-wrapper">
                {scenarios?.map((scenario) => (
                    <div
                        className={`scenario-box font-13 ${
                            scenario?.scenarioName ===
                            activeScenario?.scenarioName
                                ? "scenario-box-active"
                                : ""
                        } ${getSubmittedScenarioClassName(scenario)}`}
                        key={`ques-type-${getTrimmedValue(
                            scenario?.scenarioName,
                        )}`}
                        onClick={() => onScenarioChange(scenario)}
                    >
                        {scenario?.scenarioName}
                        <i className="fa fa-exclamation-circle" />
                        <i className="fa fa-check-circle" />
                    </div>
                ))}
            </div>
            {activeScenario?.scenarioName !== INSTRUCTIONS_SCENARIO_NAME && (
                <div className={`scenario-detail font-14 ${scenarioClassName}`}>
                    <div className="scenario-desc-header">
                        <div>
                            {questionnaireType ===
                                ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[5] && (
                                <span>Test scenario for</span>
                            )}{" "}
                            {activeScenario?.scenarioName?.toLowerCase()}
                        </div>
                        <div className="scenario-desc-header-right" />
                    </div>
                    <div className="scenario-detail-text">
                        {activeScenario?.scenarioDescription}
                    </div>
                </div>
            )}
        </>
    );
};

export default memo(ScenarioGrid);
