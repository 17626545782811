import axios from "axios";
import React from "react";
import { toast } from "react-toastify";

const createAxiosInstance = (baseURL) => {
    const HEADERS = {
        "Content-Type": "application/json",
    };

    const axiosInstance = axios.create({
        headers: HEADERS,
        baseURL,
    });

    // Add a request interceptor
    axiosInstance.interceptors.request.use(
        (config) => {
            let token = localStorage.getItem("ROCP_idToken");
            token = token?.replaceAll('"', "") ?? "";
            config.headers.authorization = `Bearer ${token}`;

            return config;
        },
        (error) => {
            // Handle request error
            return Promise.reject(error);
        },
    );

    const handleError = (error) => {
        const { response, message } = error;
        if (response) {
            const { status, statusText, data } = response;
            switch (status) {
                case 401:
                    return handleInvalidSession({ statusText });

                case 403:
                    return handleInvalidSession({
                        statusText,
                        noRedirectToPreviousPage: true,
                    });

                case 417:
                    if (data) {
                        return data;
                    }
                    break;

                case 500:
                    toast.error(
                        <div>
                            <div>
                                <b>Message:</b> {statusText}
                            </div>
                            <div>
                                <b>Status code:</b> {status}
                            </div>
                            <div>
                                {data.message ||
                                    data.error ||
                                    JSON.stringify(data)}
                            </div>
                        </div>,
                    );
                    return statusText;

                default:
                    toast.error(status);
            }
        }
        return message;
    };

    const handleRequest = async (method, path, data) => {
        try {
            const response = await axiosInstance[method](path, data);
            return { ...response, body: response.data };
        } catch (error) {
            throw handleError(error);
        }
    };

    const handleInvalidSession = ({ statusText, noRedirectToPreviousPage }) => {
        toast.error("Invalid session, Please login to continue");
        sessionStorage.clear();

        if (window && window.location && window.location.assign) {
            if (noRedirectToPreviousPage) {
                window.location.assign("/logout");
            } else {
                window.location.assign(
                    "/logout?go2=" +
                        encodeURIComponent(
                            window.location.pathname + window.location.search,
                        ),
                );
            }
        }
        return statusText;
    };

    return {
        getRequest: (path) => handleRequest("get", path),
        postRequest: (path, data) => handleRequest("post", path, data),
        putRequest: (path, data) => handleRequest("put", path, data),
        delRequest: (path) => handleRequest("delete", path),
    };
};

export default createAxiosInstance;
