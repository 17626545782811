import React, { memo, useContext } from "react";
import { Oval } from "react-loader-spinner";
import { OBScreensContext } from "../../contexts/OBScreensContext";

const Loader = () => {
    const { isLoading } = useContext(OBScreensContext);

    return (
        isLoading && (
            <div className="loading-container">
                <Oval
                    height={40}
                    width={40}
                    color="#072233"
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#ED1B2F"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                />
            </div>
        )
    );
};

export default memo(Loader);
