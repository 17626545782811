import "./sidenav.scss";
import { memo, useContext } from "react";
import { OBScreensContext } from "../../contexts/OBScreensContext";

const SideNav = ({ isOpen }) => {
    const { isOBScreen } = useContext(OBScreensContext);
    return (
        <aside className={isOpen ? "sidebar open" : "sidebar"}>
            <div className="top-sidebar">
                <ul className="sidebar-list">
                    {isOBScreen && (
                        <>
                            <li className="inactive-logos" />
                            <li className="inactive-logos" />
                            <li className="inactive-logos" />
                            <li className="inactive-logos" />
                            <li className="inactive-logos" />
                        </>
                    )}
                </ul>
            </div>

            <div className="bottom-sidebar"></div>
        </aside>
    );
};

export default memo(SideNav);
