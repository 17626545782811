import React, { useEffect, useState } from "react";

import CreatableSelect from "react-select/creatable";

const components = {
    DropdownIndicator: null,
};

const createOption = (label, index) => ({
    label,
    value: `${index}-value-${label}`,
});

const getInputValuesFromString = (params = "") => {
    return params
        .split(",")
        .filter((val) => !!val.trim().length)
        .map((val, index) => createOption(val, index));
};

const MultiValueInput = ({
    value,
    onChange,
    className = "",
    classNamePrefix = "",
}) => {
    const [inputValue, setInputValue] = useState("");
    const [values, setValues] = useState(getInputValuesFromString(value));

    useEffect(() => {
        setValues(getInputValuesFromString(value));
    }, [value]);

    const handleChange = (_values) => {
        setValues(_values);
    };
    const handleInputChange = (_inputValue) => {
        if (_inputValue.includes(",")) {
            const _newValues = _inputValue
                .split(",")
                .filter((val) => !!val.trim().length)
                .map((val, index) => createOption(val, index));

            setInputValue("");
            setValues([...values, ..._newValues]);
        } else setInputValue(_inputValue);
    };
    const handleKeyDown = (event) => {
        if (!inputValue) return;
        switch (event.key) {
            case "Enter":
            case "Tab":
                setInputValue("");
                setValues([...values, createOption(inputValue, values.length)]);

                event.preventDefault();
                break;
            default:
        }
    };

    return (
        <CreatableSelect
            components={components}
            inputValue={inputValue}
            isClearable
            isMulti
            menuIsOpen={false}
            onChange={handleChange}
            onInputChange={handleInputChange}
            onKeyDown={handleKeyDown}
            placeholder="Press Enter/Tab"
            defaultValue={getInputValuesFromString(value)}
            value={values}
            className={`${className} react-select-container`}
            classNamePrefix={`${classNamePrefix} react-select`}
            onBlur={(_event) => {
                if (_event.target?.value) {
                    values.push(
                        createOption(_event.target?.value, values.length),
                    );
                    setInputValue("");
                    setValues([...values]);
                }
                onChange(values.map(({ label }) => label).join(","));
            }}
        />
    );
};

export default MultiValueInput;
