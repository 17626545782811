import React, { memo } from "react";
import { Button } from "reactstrap";

const SandboxLaunchScreen = ({ handleNext }) => {
    return (
        <>
            <div>
                <h1 className="stepper-header">
                    Amazing, you can now start developing to sandbox
                </h1>
                <div className="stepper-desc mt-4">
                    <p className="stepper-desc-sub-header">
                        Ok, It’s now time to write to the Hotel Trader APIs in
                        our sandbox environment.
                    </p>
                    <p>
                        This page contains everything you need to build your
                        integration. If you have any questions or challenges,
                        you can reach our support team here, or via Slack if you
                        have a channel created with Hotel Trader.
                    </p>
                    When you’re ready to certify your development, come back and
                    click through to our Certification App.
                </div>
            </div>
            <Button
                type="submit"
                className="stepper-submit-btn mt-5"
                size="lg"
                onClick={handleNext}
            >
                Awesome, let's go
            </Button>
        </>
    );
};

export default memo(SandboxLaunchScreen);
