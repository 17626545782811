import logo from "../../assets/img/HTLogo.svg";
import "./footer.scss";
import { Link } from "react-router-dom";
import { memo } from "react";

const DEMAND_MAIL = "demand@hoteltrader.com";

const Footer = () => {
    return (
        <footer className={"footer"}>
            <Link to="/">
                <img src={logo} className="logo" alt="Hoteltrader" />
            </Link>
            <Link
                to="https://hoteltrader.com/about/"
                target="_blank"
                className="about-us footer-links"
            >
                <span>About Us</span>
            </Link>
            <Link to="/docs" className="api-docs footer-links">
                <span>Documents</span>
            </Link>
            <Link
                to="https://hoteltrader.com/privacy-policy/"
                target="_blank"
                className="user-policy footer-links"
            >
                <span>User Policy</span>
            </Link>
            <Link
                to={`mailto:${DEMAND_MAIL}`}
                target="_blank"
                className="contact footer-links"
            >
                <span>Contact</span>
            </Link>
        </footer>
    );
};

export default memo(Footer);
