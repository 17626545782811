import React, { memo, useMemo } from "react";
import { Button } from "reactstrap";

const PlatformScreen = ({ handleNext, isPlatformActive }) => {
    const getHeaderText = useMemo(() => {
        if (isPlatformActive) {
            return "Congrats, you’re connection is active through your platform!";
        }
        return "It’s over to your platform now…";
    }, [isPlatformActive]);

    const getDescSubText = useMemo(() => {
        if (isPlatformActive) {
            return "Congratulations, your connection to Hotel Trader has been activated by your platform.";
        }
        return "We’re waiting for your tech platform to confirm your connection is active. ";
    }, [isPlatformActive]);

    const getDescText = useMemo(() => {
        if (isPlatformActive) {
            return [
                "All we need to do now is make sure you are mapped to all your available properties and complete test  bookings in the live environment…",
                "You will find all the data you need here on this screen and when you’re ready, click through to our live test booking app.",
            ];
        }
        return [
            "Thanks for providing us with information about your set-up, we are just waiting for your 3rd party tech platform to provide us with confirmation that your feed has been activated.",
            "As soon as you receive confirmation, you can come back to complete a test booking before going live.",
            "In the meantime, you can access your mapping and meta files here on this page to make sure you’re ready to hit the ground running.",
        ];
    }, [isPlatformActive]);

    return (
        <>
            <div>
                <h1 className="stepper-header">{getHeaderText}</h1>
                <div className="stepper-desc mt-4">
                    <p className="stepper-desc-sub-header">{getDescSubText}</p>
                    {getDescText.map((text) => (
                        <p key={text}>{text}</p>
                    ))}
                </div>
            </div>
            {isPlatformActive && (
                <Button
                    type="submit"
                    className="stepper-submit-btn mt-5"
                    size="lg"
                    disabled={!isPlatformActive}
                    onClick={handleNext}
                >
                    Let’s go!
                </Button>
            )}
        </>
    );
};

export default memo(PlatformScreen);
