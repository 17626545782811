import React, { memo, useMemo } from "react";
import Select from "react-select";

const SelectDropdownQuestion = ({
    question,
    options,
    selectedValue,
    id,
    updateAnswer,
    quesIndex,
    isSubmitted,
}) => {
    const getOptions = useMemo(() => {
        return options.map(({ name }) => {
            return { value: name, label: name };
        });
    }, [options]);

    const getSelectOption = useMemo(() => {
        return selectedValue
            ? { value: selectedValue, label: selectedValue }
            : null;
    }, [selectedValue]);

    return (
        <div className="select-dropdown-ques-wrapper">
            <div className="grouping-question-box">
                <div className="grouping-question-ques-name">
                    <div>{quesIndex}.</div>
                    <div>{question}</div>
                </div>
                <div
                    className={`${
                        getSelectOption
                            ? "grouping-question-ques-option-item-dropdown-selected"
                            : ""
                    } 
                grouping-question-ques-option-item`}
                >
                    <Select
                        defaultValue={getSelectOption}
                        onChange={(val) => {
                            updateAnswer(id, val?.value);
                        }}
                        options={getOptions}
                        isDisabled={isSubmitted}
                        theme={(theme) => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary: "#0ac578",
                            },
                        })}
                        classNamePrefix="grouping-question-ques-select-dropdown"
                    />
                </div>
            </div>
        </div>
    );
};

export default memo(SelectDropdownQuestion);
