import VerticalLinearStepper from "../../components/vertical-stepper/VerticalLinearStepper";
import React, { memo, useCallback, useContext, useMemo } from "react";
import WelcomeStep from "./ob-screens/WelcomeScreen";
import APIQuestionnaireLaunchScreen from "./ob-screens/APIQestionnaireLaunchScreen";
import {
    ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS,
    MAPPING_FILES_SCREENS,
    SCREEN_TYPE_DASHBOARD_STEP_NUMBER,
} from "../../constants";
import { OBScreensContext } from "../../contexts/OBScreensContext";
import SandboxLaunchScreen from "./ob-screens/SandboxLaunchScreen";
import "./dashboard.scss";
import DashboardFiles from "./ob-screens/DashboardFiles";
import CertifiedScreen from "./ob-screens/CertifiedScreen";
import PlatformScreen from "./ob-screens/PlatformScreen";
import ProdTestLaunchScreen from "./ob-screens/ProdTestLaunchScreen";
import CertificationCompleteScreen from "./ob-screens/CertificationCompleteScreen";

const dashboardSteps = [
    {
        label: "",
        description: ``,
        id: 1,
    },
    {
        label: "",
        description: "",
        id: 2,
    },
    {
        label: "",
        description: ``,
        id: 3,
    },
];

const DashboardStepper = () => {
    const { setDashboardStep, clientOnboardingScreen } =
        useContext(OBScreensContext);

    const getActiveStep = useMemo(() => {
        return SCREEN_TYPE_DASHBOARD_STEP_NUMBER[
            clientOnboardingScreen.onboardingScreen
        ];
    }, [clientOnboardingScreen.onboardingScreen]);

    const setClientOnboardingScreen = useCallback(
        (onboardingScreen) => {
            const cOBScreen = { ...clientOnboardingScreen, onboardingScreen };
            setDashboardStep(cOBScreen);
        },
        [clientOnboardingScreen, setDashboardStep],
    );

    const getStepper = useMemo(() => {
        return {
            [ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[1]]: (
                <APIQuestionnaireLaunchScreen
                    handleNext={() =>
                        setClientOnboardingScreen(
                            ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[2],
                        )
                    }
                />
            ),
            [ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[4]]: (
                <SandboxLaunchScreen
                    handleNext={() =>
                        setClientOnboardingScreen(
                            ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[5],
                        )
                    }
                />
            ),
            [ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[6]]: (
                <CertifiedScreen
                    handleNext={() =>
                        setClientOnboardingScreen(
                            ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[10],
                        )
                    }
                />
            ),
            [ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[7]]: (
                <PlatformScreen
                    handleNext={() =>
                        setClientOnboardingScreen(
                            ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[7],
                        )
                    }
                    isPlatformActive={false}
                />
            ),
            [ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[8]]: (
                <PlatformScreen
                    handleNext={() =>
                        setClientOnboardingScreen(
                            ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[10],
                        )
                    }
                    isPlatformActive={true}
                />
            ),
            [ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[9]]: (
                <ProdTestLaunchScreen
                    handleNext={() =>
                        setClientOnboardingScreen(
                            ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[10],
                        )
                    }
                />
            ),
            [ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[12]]: (
                <CertificationCompleteScreen
                    handleNext={() =>
                        setClientOnboardingScreen(
                            ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[12],
                        )
                    }
                />
            ),
        }[clientOnboardingScreen.onboardingScreen];
    }, [clientOnboardingScreen.onboardingScreen, setClientOnboardingScreen]);

    return (
        <div className="dashboard-stepper">
            {clientOnboardingScreen.onboardingScreen === "" ? (
                <></>
            ) : (
                <>
                    {getActiveStep !== -1 ? (
                        <>
                            <div className="dashboard-stepper-step-count">
                                <p>Certification</p>
                                <p className="dashboard-stepper-step-count-label">
                                    <span
                                        className={`${
                                            getActiveStep !== 0 &&
                                            "active-step-label"
                                        }`}
                                    >
                                        {getActiveStep}
                                    </span>{" "}
                                    of 3 steps completed
                                </p>
                            </div>
                            <div className="stepper-main-wrapper">
                                <VerticalLinearStepper
                                    steps={dashboardSteps}
                                    activeStep={getActiveStep}
                                />

                                <div className="stepper-desc-wrapper">
                                    {getStepper}
                                </div>
                                {MAPPING_FILES_SCREENS.includes(
                                    clientOnboardingScreen.onboardingScreen,
                                ) && (
                                    <div className="dashboard-files-wrapper">
                                        <DashboardFiles />
                                    </div>
                                )}
                            </div>
                        </>
                    ) : (
                        <div className="welcome-step">
                            <WelcomeStep
                                onSubmit={() =>
                                    setClientOnboardingScreen(
                                        ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[1],
                                    )
                                }
                            />
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default memo(DashboardStepper);
