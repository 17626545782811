import React from "react";
import greyLogo from "../assets/img/ht_logo_grey.svg";

function Empty(props) {
    return (
        <div className={"empty d-flex"}>
            <div className="p-4 d-flex flex-column align-items-center">
                <img src={greyLogo} alt="No Data" />
                <p className={"mt-4 font-14"}>{props.message}</p>
            </div>
        </div>
    );
}

export default Empty;
