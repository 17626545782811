import React, { memo, useCallback, useMemo } from "react";
import GroupedMultiChoiceMultiOptionQuestions from "../question-detail/GroupedMultiChoiceMultiOptionQuestions";
import {
    GROUPED_MULTI_OPTION,
    QUES_TYPE_SELECT,
    SINGLE_CHOICE_MULTI_OPTION,
    SINGLE_CHOICE_BINARY_OPTION,
    QUES_TYPE_NUMBER,
    QUES_TYPE_STRING,
    QUES_TYPE_NUMBER_ARRAY,
    QUES_TYPE_STRING_ARRAY,
} from "../ques-data";
import SingleChoiceMultiOptionQuestion from "../question-detail/SingleChoiceMultiOptionQuestion";
import SelectDropdownQuestion from "../question-detail/SelectDropdownQuestion";
import InputTextQuestion from "../question-detail/InputTextQuestion";
import {
    ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS,
    isAPIQuestionnaire,
} from "../../../constants";

const GroupQuestions = ({
    group,
    otherAnswers,
    groupedAnswers,
    setAnswers,
    questionnaireType,
}) => {
    const updateOtherAnswers = useCallback(
        (id, value) => {
            setAnswers("OTHERS")((prevAnswers) => ({
                ...prevAnswers,
                [id]: value,
            }));
        },
        [setAnswers],
    );

    const addOrRemoveInExistingAns = useCallback((prevAnswer, value) => {
        return prevAnswer.includes(value)
            ? prevAnswer.filter((ans) => ans !== value)
            : [...prevAnswer, value];
    }, []);

    const updateMultiChoiceAnswer = useCallback(
        (id, value) => {
            setAnswers("GROUPED_QUES")((prevAnswers) => ({
                ...prevAnswers,
                [id]: prevAnswers[id]
                    ? addOrRemoveInExistingAns(prevAnswers[id], value)
                    : [value],
            }));
        },
        [setAnswers, addOrRemoveInExistingAns],
    );

    const getAllQuestions = useMemo(() => {
        if (!group?.groupQuestions) return [];
        return group?.groupQuestions;
    }, [group?.groupQuestions]);

    const getTotalQuestionsCount = useMemo(() => {
        return getAllQuestions.length;
    }, [getAllQuestions]);

    const getTotalAnswered = useMemo(() => {
        const answers = { ...otherAnswers, ...groupedAnswers };
        return getAllQuestions.reduce((a, c) => {
            const ans = answers[c.questionId];
            if (ans || ans === 0) {
                if (!Array.isArray(ans) || ans.length > 0) {
                    a = a + 1;
                }
            }
            return a;
        }, 0);
    }, [getAllQuestions, otherAnswers, groupedAnswers]);

    // if correct answer is submitted
    const isQuestionDisabled = useCallback(
        (answerApproved, answerForceCorrect) => {
            if (isAPIQuestionnaire(questionnaireType) && !answerApproved) {
                return false;
            }
            return answerForceCorrect || answerApproved;
        },
        [questionnaireType],
    );

    const getIsWrongAnsSubmitted = useCallback(
        (answerApproved, answerForceCorrect, submittedBy) => {
            return (
                !answerForceCorrect && answerApproved === false && submittedBy
            );
        },
        [],
    );

    const getQuestion = (type, ques) => {
        switch (type) {
            case GROUPED_MULTI_OPTION:
                return (
                    <GroupedMultiChoiceMultiOptionQuestions
                        questions={ques}
                        options={ques?.length > 0 ? ques[0].options : []}
                        answers={groupedAnswers}
                        updateAnswer={updateMultiChoiceAnswer}
                        isQuestionDisabled={isQuestionDisabled}
                    />
                );
            case SINGLE_CHOICE_MULTI_OPTION:
            case SINGLE_CHOICE_BINARY_OPTION:
                return (
                    <SingleChoiceMultiOptionQuestion
                        key={`single-choice-${ques.questionId}`}
                        value={otherAnswers[ques.questionId] ?? ""}
                        question={ques.question}
                        options={ques.options}
                        id={ques.questionId}
                        isSubmitted={isQuestionDisabled(
                            ques.answerApproved,
                            ques.answerForceCorrect,
                        )}
                        quesIndex={ques.quesIndex}
                        updateAnswer={updateOtherAnswers}
                        questionnaireType={questionnaireType}
                        isWrongAnsSubmitted={getIsWrongAnsSubmitted(
                            ques.answerApproved,
                            ques.answerForceCorrect,
                            ques.submittedBy,
                        )}
                    />
                );
            case QUES_TYPE_SELECT:
                return (
                    <SelectDropdownQuestion
                        key={`ques-select-dropdown-${ques.questionId}`}
                        question={ques.question}
                        options={ques.options}
                        id={ques.questionId}
                        isSubmitted={isQuestionDisabled(
                            ques.answerApproved,
                            ques.answerForceCorrect,
                        )}
                        quesIndex={ques.quesIndex}
                        selectedValue={otherAnswers[ques.questionId] || null}
                        updateAnswer={updateOtherAnswers}
                    />
                );
            case QUES_TYPE_NUMBER:
            case QUES_TYPE_NUMBER_ARRAY:
            case QUES_TYPE_STRING:
            case QUES_TYPE_STRING_ARRAY:
                return (
                    <InputTextQuestion
                        key={`ques-input-${ques.questionId}`}
                        question={ques.question}
                        isSubmitted={isQuestionDisabled(
                            ques.answerApproved,
                            ques.answerForceCorrect,
                        )}
                        isWrongAnsSubmitted={getIsWrongAnsSubmitted(
                            ques.answerApproved,
                            ques.answerForceCorrect,
                            ques.submittedBy,
                        )}
                        placeholder={ques.placeholder}
                        value={otherAnswers[ques.questionId] ?? ""}
                        id={ques.questionId}
                        quesIndex={ques.quesIndex}
                        updateAnswer={updateOtherAnswers}
                        sampleAnswerFormat={ques.sampleAnswerFormat}
                        questionnaireType={questionnaireType}
                    />
                );
            default:
                return null;
        }
    };

    const getGroupQuestionsBorderClassName = useMemo(() => {
        let className = "grouping-border";
        if (
            questionnaireType === ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[2] ||
            questionnaireType === ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[3]
        ) {
            className = `api-client-grouping-border ${className}`;
        }
        return className;
    }, [questionnaireType]);

    return (
        <div className="grey">
            <div className="quest">
                <div className="grouping-name">
                    <p className="grouping-header header-color">
                        {group?.groupName}
                    </p>
                    <p className="grouping-progress">
                        {getTotalAnswered} of {getTotalQuestionsCount} answered
                    </p>
                </div>
                {group?.separatedQues?.groupedQues?.length > 0 && (
                    <>
                        {getQuestion(
                            GROUPED_MULTI_OPTION,
                            group?.separatedQues?.groupedQues,
                        )}
                    </>
                )}
                {group?.separatedQues?.groupedQues?.length === 0 && (
                    <div className={getGroupQuestionsBorderClassName} />
                )}
                {group?.separatedQues?.questions?.map((ques) => (
                    <div key={`group-ques-${ques.questionId}-div`}>
                        {getQuestion(ques.type, ques)}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default memo(GroupQuestions);
