import { ToastContainer } from "react-toastify";
import React from "react";
import "react-toastify/dist/ReactToastify.min.css";

const Toastr = () => {
    return (
        <ToastContainer
            position="top-right"
            hideProgressBar
            newestOnTop
            closeOnClick
            pauseOnVisibilityChange
            draggable
            pauseOnHover
        />
    );
};

export default Toastr;
