import React, { useState } from "react";
import { Button } from "reactstrap";
import Empty from "../../components/Empty";
import TableContent from "../../components/table/TableContent";
import TeamSummary from "./TeamSummary";
import AddTeam from "./AddTeam";

function Team(props) {
    const [showAddTeamModal, setShowAddTeamModal] = useState(false);

    return (
        <div id="team">
            <h3>Team mates</h3>
            <p className="font-12">
                These are all your team members. Edit their access info or block
                them from here.
            </p>

            <Button
                type="submit"
                className="full-btn w-100 mt-5 mb-5"
                size="lg"
                color="primary"
                onClick={() => setShowAddTeamModal(true)}
            >
                <i className="fa fa-plus" />
                &nbsp;&nbsp;Add a New Member
            </Button>

            <Empty
                message={"Your pending invitation requests will show up here!"}
            />

            <TeamSummary />

            <div className={"mt-5"}>
                <TableContent title={"Team mates"} />
            </div>
            {showAddTeamModal && (
                <AddTeam
                    showAddTeam={showAddTeamModal}
                    showOrHideModal={setShowAddTeamModal}
                />
            )}
        </div>
    );
}

export default Team;
