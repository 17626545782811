import React, { memo } from "react";
import { Button } from "reactstrap";

const ProdTestLaunchScreen = ({ handleNext }) => {
    return (
        <>
            <div>
                <h1 className="stepper-header">
                    Let’s do some Prod Testing before you go live
                </h1>
                <div className="stepper-desc mt-4">
                    <p>
                        Making sure all the data is mapped correctly in the live
                        environment.
                    </p>
                    <p>
                        Please book the cheapest <b>refundable</b> room
                        according to the below search criteria and enter the
                        data returned in the fields provided.
                    </p>
                    <p>
                        We also need the request and response logs for your
                        search, quote (if configured) and book calls - this lets
                        us head off any potential issues early on!
                    </p>
                </div>
            </div>
            <Button
                type="submit"
                className="stepper-submit-btn mt-5"
                size="lg"
                onClick={handleNext}
            >
                Awesome, let's go
            </Button>
        </>
    );
};

export default memo(ProdTestLaunchScreen);
