import React, { memo } from "react";
import { Button } from "reactstrap";

const CertificationCompleteScreen = ({ handleNext }) => {
    return (
        <>
            <div>
                <h1 className="stepper-header">
                    Amazing! Congrats on passing Certification! ✅
                </h1>
                <div className="stepper-desc mt-4">
                    <p className="complete-screen-stepper-desc stepper-desc-sub-header">
                        We will follow up with you on a confirmed go-live date
                        within 48 hours.
                    </p>
                    <p>
                        In the meantime click through your Hotel Trader
                        dashboard where you will see your first bookings come in
                        and can find useful information and copies of your
                        documents.
                    </p>
                    <p>Welcome to the future of lodging distribution!</p>
                </div>
            </div>
            <Button
                type="submit"
                className="stepper-submit-btn mt-5"
                size="lg"
                onClick={handleNext}
            >
                Let’s go
            </Button>
        </>
    );
};

export default memo(CertificationCompleteScreen);
