import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useAuth } from "./AuthContext";
import { ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS } from "../constants";
import { getOBDashboardStep, updateOBDashboardStep } from "../api";
import { toast } from "react-toastify";

export const OBScreensContext = React.createContext({});

export const OBScreensProvider = React.memo(({ children }) => {
    const { idToken } = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [clientOnboardingScreen, setClientOnboardingScreen] = useState({});

    const getDashboardStep = useCallback(() => {
        setIsLoading(true);
        getOBDashboardStep().then(
            (res) => {
                setIsLoading(false);
                setClientOnboardingScreen(res.data);
                // setClientOnboardingScreen({
                //     ...res.data,
                //     onboardingScreen: ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[3],
                // });
            },
            () => {
                setIsLoading(false);
                toast.error("Unable to load, please try again later");
            },
        );
    }, []);

    useEffect(() => {
        if (idToken) {
            getDashboardStep();
        }
    }, [idToken, getDashboardStep]);

    const setDashboardStep = useCallback(
        (cOBScreen) => {
            setIsLoading(true);
            updateOBDashboardStep(cOBScreen).then(
                () => {
                    setIsLoading(false);
                    // setClientOnboardingScreen(cOBScreen);
                    getDashboardStep();
                },
                () => {
                    setIsLoading(false);
                    toast.error("Unable to update, please try again later");
                },
            );
        },
        [getDashboardStep],
    );

    const contextValue = useMemo(
        () => ({
            isOBScreen:
                clientOnboardingScreen.onboardingScreen === "" ||
                ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS.includes(
                    clientOnboardingScreen.onboardingScreen,
                ),
            setDashboardStep,
            getDashboardStep,
            isLoading,
            setIsLoading,
            clientOnboardingScreen,
            setClientOnboardingScreen,
        }),
        [
            setDashboardStep,
            getDashboardStep,
            isLoading,
            setIsLoading,
            clientOnboardingScreen,
        ],
    );

    return (
        <OBScreensContext.Provider value={contextValue}>
            {children}
        </OBScreensContext.Provider>
    );
});
