import React, { memo, useMemo } from "react";
import {
    ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS,
    UNDER_REVIEW_QUESTIONNAIRE_SCREENS,
} from "../../constants";
import Questionnaire from "../../components/cert-tabs/Questionnaire";
import hourglass from "../../assets/img/hourglass.gif";

const UnderReviewBanner = () => {
    return (
        <div className="api-client-under-review-wrapper">
            <div className="under-review-img">
                <img src={hourglass} className="loading" alt="loading" />
            </div>
            <div className="under-review-desc">
                <div className="under-review-desc-header">
                    Thanks for sending that over!
                </div>
                <div className="under-review-desc-sub">
                    A member of the Hotel Trader team will review your responses
                    and let you know when we’re good to go!
                </div>
            </div>
        </div>
    );
};

const DashboardQuestionnaire = ({ questionnaireType }) => {
    const getQuestionnaire = useMemo(() => {
        switch (questionnaireType) {
            case ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[2]:
            case ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[3]:
            case ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[5]:
            case ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[10]:
            case ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[11]:
                return (
                    <div
                        key={`dashboard-stepper-questionnaire-${questionnaireType}`}
                    >
                        {UNDER_REVIEW_QUESTIONNAIRE_SCREENS.includes(
                            questionnaireType,
                        ) && <UnderReviewBanner />}
                        <Questionnaire
                            key={`dashboard-questionnaire-${questionnaireType}`}
                            questionnaireType={questionnaireType}
                        />
                    </div>
                );
            default:
                return <p>Questionnaire</p>;
        }
    }, [questionnaireType]);

    return <div>{getQuestionnaire}</div>;
};

export default memo(DashboardQuestionnaire);
