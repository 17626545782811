import moment from "moment-timezone";
import dateFormat from "dateformat";

export const validateEmail = (email) => {
    return /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(email);
};

export const USER_ROLES = {
    USER: "USER",
    SUPER_USER: "SUPER_USER",
};

export const ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS = [
    "OB_WELCOME",
    "OB_API_QUESTIONNAIRE_LAUNCH",
    "API_QUESTIONNAIRE",
    "API_QUESTIONNAIRE_UNDER_REVIEW",
    "OB_SANDBOX_QUESTIONNAIRE_LAUNCH",
    "SANDBOX_QUESTIONNAIRE",
    "OB_SANDBOX_CERTIFIED",
    "OB_PLATFORM_ACTIVATION_PENDING",
    "OB_PLATFORM_ACTIVATED",
    "OB_PROD_TESTING_LAUNCH",
    "PROD_TESTING_QUESTIONNAIRE",
    "PROD_TESTING_QUESTIONNAIRE_UNDER_REVIEW",
    "CERTIFICATION_COMPLETE",
];

export const CLIENT_ACCOUNT_DETAILS_FORM = "CLIENT_ACCOUNT_DETAILS_FORM";

export const QUESTIONNAIRE_SCREENS = [
    ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[2],
    ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[3],
    ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[5],
    ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[10],
    ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[11],
];

export const UNDER_REVIEW_QUESTIONNAIRE_SCREENS = [
    ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[3],
    ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[11],
];

export const MAPPING_FILES_SCREENS = [
    ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[4],
    ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[6],
    ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[7],
    ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[8],
    ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[9],
    ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[12],
];

export const QUESTIONNAIRE_TYPES = {
    [ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[2]]: "client_api",
    [ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[3]]: "client_api",
    [ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[5]]: "sandbox",
    [ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[10]]: "prod",
    [ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[11]]: "prod",
    [CLIENT_ACCOUNT_DETAILS_FORM]: "client_account_details",
};

export const SCREEN_TYPE_DASHBOARD_STEP_NUMBER = {
    [ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[0]]: -1,
    [ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[1]]: 0,
    [ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[4]]: 1,
    [ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[6]]: 2,
    [ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[7]]: 2,
    [ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[8]]: 2,
    [ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[9]]: 2,
    [ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[12]]: 3,
};

export const INSTRUCTIONS_SCENARIO_NAME = "Instructions";

export const getTrimmedValue = (val) => {
    return val ? val.replaceAll(/\s/g, "") : "";
};

export const getFormattedDate = (value, format = "yyyy-mm-dd") => {
    if (!!value)
        try {
            const dateStr = dateFormat(moment(value), format);
            return dateStr;
        } catch (error) {
            console.log("invalid date: " + value);
        }
    return "NA";
};

export const compareArrays = (array1, array2) => {
    if (array1.length !== array2.length) {
        return false; // Arrays have different lengths, so they can't be the same
    }

    return array1.every((value) => array2.includes(value));
};

export const isAPIQuestionnaire = (questionnaireType) => {
    return (
        questionnaireType === ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[2] ||
        questionnaireType === ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[3]
    );
};

export const getUnionOfArrays = (array1, array2 = []) => {
    const unionSet = new Set(array1.concat(array2));
    return Array.from(unionSet);
};

export const MAPPING_FILES_PREFIX =
    "https://tgx-metadata-payload-qa.s3.amazonaws.com";

export const STOPLIGHT_URL = "https://hotel-trader.stoplight.io/";

export const INSOMNIA_FILES_URL =
    "https://s3.amazonaws.com/hoteltrader.com/pullapi-graphql.json";

export const GRAPHQL_SEARCH_URL =
    "https://search-sandbox.hoteltrader.com/graphql";

export const GRAPHQL_SEARCH_URL_PROD = "https://search.hoteltrader.com/graphql";

export const GRAPHQL_RESERVATION_URLS = {
    QUOTE: "https://quote-sandbox.hoteltrader.com/graphql",
    BOOK: "https://book-sandbox.hoteltrader.com/graphql",
    RESERVATION: "https://res-detail-sandbox.hoteltrader.com/graphql",
};

export const GRAPHQL_RESERVATION_URLS_PROD = {
    QUOTE: "https://quote.hoteltrader.com/graphql",
    BOOK: "https://book.hoteltrader.com/graphql",
    RESERVATION: "https://res-detail.hoteltrader.com/graphql",
};

export const getMappingFileName = (mappingFilesPrefix, fileType) => {
    return `${mappingFilesPrefix}/${fileType}`;
};
