import "./login.css";
import logo from "../../assets/img/ht-logo-login.svg";
import { Button, Form } from "reactstrap";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";

function LoginPage() {
    const { login, idToken } = useAuth();

    const navigate = useNavigate();
    if (idToken) {
        navigate("/");
    }

    return (
        <div className="auth">
            <div className="auth-container">
                <div className="login-logo">
                    <img src={logo} className="logo" alt="Hoteltrader" />
                </div>
                <div className="login-title font-13">Client Portal</div>
                <div className="login-form">
                    <Form
                        className={"w-100"}
                        onSubmit={(e) => e.preventDefault()}
                    >
                        <Button
                            type="submit"
                            className="full-btn w-100"
                            size="lg"
                            color="primary"
                            onClick={login}
                        >
                            {/*Login with miniOrange*/}
                            Login
                        </Button>
                    </Form>
                </div>
            </div>
        </div>
    );
}

export default LoginPage;
