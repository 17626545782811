import "./index.scss";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { GROUPED_MULTI_OPTION } from "../questions/ques-data";
import {
    ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS,
    CLIENT_ACCOUNT_DETAILS_FORM,
    getFormattedDate,
    isAPIQuestionnaire,
} from "../../constants";

const IN_PROGRESS = "In progress";
const NOT_STARTED = "Not started";

const ScenarioGroupingList = ({
    selectedGroup,
    updateSelectedGroup,
    allQuestionsByScenario,
    allAnswers,
    selectedScenarioStatus,
    selectedScenarioSubmittedStatus,
    questionnaireType,
}) => {
    const [groupsProgress, setGroupProgress] = useState({});

    const getSubmittedGroupClassName = useCallback(
        (groupId) => {
            if (
                questionnaireType === CLIENT_ACCOUNT_DETAILS_FORM ||
                isAPIQuestionnaire(questionnaireType)
            )
                return "";
            if (selectedScenarioSubmittedStatus) {
                if (
                    Object.keys(selectedScenarioStatus[groupId] ?? []).length >
                    0
                ) {
                    return "error-scenario-group";
                } else {
                    return "success-scenario-group";
                }
            }
            return "";
        },
        [
            selectedScenarioSubmittedStatus,
            selectedScenarioStatus,
            questionnaireType,
        ],
    );

    const getWrapperClassName = useMemo(() => {
        let className = "ques-grouping q-carousel";
        if (questionnaireType === ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[5]) {
            className = `${className} sandbox-ques-grouping`;
        }
        return className;
    }, [questionnaireType]);

    const getStatusOnGroupQuestions = useCallback(
        (groupQuestions) => {
            return groupQuestions.some((ques) => {
                return (
                    allAnswers &&
                    allAnswers[ques.questionId] &&
                    (ques.type !== GROUPED_MULTI_OPTION ||
                        allAnswers[ques.questionId].length > 0)
                );
            })
                ? IN_PROGRESS
                : NOT_STARTED;
        },
        [allAnswers],
    );

    useEffect(() => {
        setGroupProgress(
            allQuestionsByScenario?.reduce(
                (a, { groupName, groupQuestions, groupLastUpdated }) => {
                    a[groupName] =
                        selectedGroup === groupName
                            ? getStatusOnGroupQuestions(groupQuestions)
                            : `last update ${getFormattedDate(
                                  groupLastUpdated,
                                  "dS mmm yyyy",
                              )}`;
                    return a;
                },
                {},
            ),
        );
    }, [allQuestionsByScenario, selectedGroup, getStatusOnGroupQuestions]);

    return (
        <div className={getWrapperClassName}>
            {allQuestionsByScenario?.map(({ groupName, groupId }) => (
                <div
                    key={`q-carousel-ques-grouping-${groupId}`}
                    className={`${
                        selectedGroup === groupName
                            ? "active-ques-grouping-list-item"
                            : ""
                    } ${getSubmittedGroupClassName(
                        groupId,
                    )} ques-grouping-list-item`}
                    onClick={() => updateSelectedGroup(groupName)}
                >
                    <div className="ques-grouping-desc">
                        <p className="ques-grouping-header">{groupName}</p>
                        <p className="ques-grouping-progress">
                            {groupsProgress[groupName]}
                        </p>
                    </div>
                    <div
                        className={`${
                            selectedGroup === groupName
                                ? "active-ques-grouping-img"
                                : ""
                        } ques-grouping-img`}
                    >
                        {groupsProgress[groupName] === "In progress" ? (
                            <i className="fa fa-hourglass-start fa-visible" />
                        ) : (
                            <>
                                <i className="fa fa-exclamation-circle" />
                                <i className="fa fa-check-circle" />
                            </>
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default memo(ScenarioGroupingList);
