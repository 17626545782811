import { Input } from "reactstrap";
import React, { useMemo } from "react";
import "./question-detail.scss";
import { CLIENT_ACCOUNT_DETAILS_FORM } from "../../../constants";

const InputTextQuestion = ({
    question,
    id,
    value,
    quesIndex,
    placeholder,
    updateAnswer,
    sampleAnswerFormat,
    isSubmitted,
    isWrongAnsSubmitted,
    questionnaireType,
}) => {
    const getInputClassName = useMemo(() => {
        let className = "group-gues-input-wrapper";
        if (questionnaireType !== CLIENT_ACCOUNT_DETAILS_FORM) {
            if (isSubmitted) {
                className = `${className} group-gues-input-submitted-wrapper`;
            }
            if (isWrongAnsSubmitted) {
                className = `${className} group-gues-input-error-wrapper`;
            }
        }
        return className;
    }, [questionnaireType, isSubmitted, isWrongAnsSubmitted]);

    return (
        <div className="group-input-text-question">
            <div className="input-text-label">
                <span className="input-text-label">{question}</span>
                {sampleAnswerFormat && (
                    <span className="input-sample-answer">
                        eg: {sampleAnswerFormat}
                    </span>
                )}
            </div>
            <div className={getInputClassName}>
                <Input
                    id={id}
                    value={value}
                    name={`text-input-name-${id}`}
                    placeholder={placeholder ?? "Type here"}
                    type="text"
                    onChange={(e) => updateAnswer(id, e.target.value)}
                    disabled={isSubmitted}
                />
                <i className="fa fa-exclamation-circle" />
                <i className="fa fa-check-circle" />
            </div>
        </div>
    );
};

export default InputTextQuestion;
