import React, { useCallback } from "react";
import "./questionnaire.scss";
import {
    getMappingFileName,
    GRAPHQL_RESERVATION_URLS,
    GRAPHQL_SEARCH_URL,
    INSOMNIA_FILES_URL,
    STOPLIGHT_URL,
} from "../../../constants";
import { Button, Input } from "reactstrap";
import { toast } from "react-toastify";

const SandboxInstructionsMappingFiles = ({
    authToken,
    generateToken,
    mappingFilesPrefix,
}) => {
    const copyToClipboard = () => {
        navigator.clipboard.writeText(authToken).then(() => {
            toast.success("Copied token to clipboard!");
        });
    };

    const getReservationUrl = useCallback((type) => {
        return GRAPHQL_RESERVATION_URLS[type];
    }, []);

    return (
        <div className="sandbox-instructions-mapping-files-wrapper">
            <div className="files-wrapper mapping-files-wrapper">
                <div className="file-wrapper-div">
                    <i className="fa fa-paper-plane file-fa" />
                    <span className="file-wrapper-name">
                        Sandbox mapping files
                    </span>
                </div>
                <div className="files-details-div">
                    <p className="files-details-header">
                        These are your property mapping files.
                    </p>
                    <p className="files-details-desc">
                        Please use these files to make sure you are mapped to
                        the sandbox properties you need to certify.
                    </p>
                    <p className="files-details-desc">
                        <i className="fa fa-paperclip" />
                        <a
                            href={getMappingFileName(
                                mappingFilesPrefix,
                                "HTR_parent_region_list.csv",
                            )}
                        >
                            Parent Region List
                        </a>
                    </p>
                    <p className="files-details-desc">
                        <i className="fa fa-paperclip" />
                        <a
                            href={getMappingFileName(
                                mappingFilesPrefix,
                                "HTR_property_static_data.csv",
                            )}
                        >
                            Property Static Data
                        </a>
                    </p>
                    <p className="files-details-desc">
                        <i className="fa fa-paperclip" />
                        <a
                            href={getMappingFileName(
                                mappingFilesPrefix,
                                "HTR_rooms.csv",
                            )}
                        >
                            Rooms
                        </a>
                    </p>
                    <p className="files-details-desc">
                        <i className="fa fa-paperclip" />
                        <a
                            href={getMappingFileName(
                                mappingFilesPrefix,
                                "HTR_meal_plan_options.csv",
                            )}
                        >
                            Mealplan Options
                        </a>
                    </p>
                </div>
            </div>
            <div className="files-wrapper dev-docs-wrapper">
                <div className="file-wrapper-div">
                    <i className="fa fa-file-text file-fa" />
                    <span className="file-wrapper-name">Dev docs</span>
                </div>

                <div className="files-details-div">
                    <p className="files-details-header">
                        These are your development documents.
                    </p>
                    <div className="files-details-desc">
                        <p>
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href={STOPLIGHT_URL}
                            >
                                Stoplight Docs
                            </a>
                        </p>
                        <p>
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href={INSOMNIA_FILES_URL}
                            >
                                Insomnia Files
                            </a>
                        </p>
                        <p>
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href={GRAPHQL_SEARCH_URL}
                            >
                                Search -{" "}
                                <span className="file-url">
                                    {GRAPHQL_SEARCH_URL}
                                </span>
                            </a>
                        </p>
                        <p>
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href={getReservationUrl("QUOTE")}
                            >
                                Quote -{" "}
                                <span className="file-url">
                                    {getReservationUrl("QUOTE")}
                                </span>
                            </a>
                        </p>
                        <p>
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href={getReservationUrl("BOOK")}
                            >
                                Book, Modify and Cancel -{" "}
                                <span className="file-url">
                                    {getReservationUrl("BOOK")}
                                </span>
                            </a>
                        </p>
                        <p>
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href={getReservationUrl("RESERVATION")}
                            >
                                Get reservation -{" "}
                                <span className="file-url">
                                    {getReservationUrl("RESERVATION")}
                                </span>
                            </a>
                        </p>
                    </div>
                </div>
            </div>
            <div className="files-wrapper token-wrapper">
                <div className="file-wrapper-div">
                    <i className="fa fa-shield file-fa" />
                    <span className="file-wrapper-name">
                        Sandbox auth token
                    </span>
                    <div className="files-btn-div">
                        <Button
                            type="submit"
                            className="files-btn mt-5"
                            size="lg"
                            onClick={generateToken}
                        >
                            generate token
                        </Button>
                    </div>
                </div>

                <div className="files-details-div">
                    <p className="files-details-header">
                        All tokens are unique!
                    </p>
                    <p className="files-details-desc">
                        Generating a new token replaces the previous token,
                        please ensure you are using the most recent token or
                        calls will fail.
                    </p>
                    <div className="token-input-div">
                        <Input
                            type="text"
                            value={authToken}
                            disabled
                            className="files-auth-input"
                        />
                        <span
                            className="token-copy-text"
                            onClick={copyToClipboard}
                        >
                            <i className="fa fa-clone" />
                            copy
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SandboxInstructionsMappingFiles;
