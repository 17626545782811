import React, { memo } from "react";
import { getTrimmedValue } from "../../../constants";

const renderOptions = (options, id, answers, updateAnswer, isSubmitted) => {
    return options?.map((option) => (
        <div
            key={`grouped-multi-option-ques-option-${
                !id ? "header" : id
            }-${getTrimmedValue(option.name)}`}
            className={`${
                !id ? "grouped-multi-option-ques-option-item-header" : ""
            } grouping-question-ques-option-item grouped-multi-option-ques-option-item`}
        >
            {!id ? (
                option.name
            ) : (
                <div
                    className={`${
                        answers[id] && answers[id].includes(option.name)
                            ? "grouped-multi-option-ques-option-item-select-checked"
                            : ""
                    } ${
                        option.disabled || isSubmitted
                            ? "disabled-grouped-option"
                            : ""
                    } 
                    grouped-multi-option-ques-option-item-select`}
                    onClick={() => {
                        if (!option.disabled && !isSubmitted) {
                            updateAnswer(id, option.name);
                        }
                    }}
                />
            )}
        </div>
    ));
};

const GroupedMultiChoiceMultiOptionQuestions = ({
    questions,
    options,
    answers,
    updateAnswer,
    isQuestionDisabled,
}) => {
    return (
        <div className="grouped-multi-option-ques-wrapper">
            <div className="grouping-question-box grouped-multi-option-ques-box-header">
                <div className="grouping-question-ques-name grouped-multi-option-ques-name" />
                {renderOptions(options, null)}
            </div>

            {questions?.map(
                ({
                    question,
                    questionId,
                    quesIndex,
                    options,
                    answerForceCorrect,
                    answerApproved,
                }) => (
                    <div
                        key={`grouped-multi-option-ques-${questionId}`}
                        className="grouping-question-box grouped-multi-option-ques-box"
                    >
                        <div className="grouping-question-ques-name grouped-multi-option-ques-name">
                            <div>{quesIndex}.</div>
                            <div>{question}</div>
                        </div>
                        {renderOptions(
                            options,
                            questionId,
                            answers,
                            updateAnswer,
                            isQuestionDisabled(
                                answerApproved,
                                answerForceCorrect,
                            ),
                        )}
                    </div>
                ),
            )}
        </div>
    );
};

export default memo(GroupedMultiChoiceMultiOptionQuestions);
