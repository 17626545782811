import React, { useContext } from "react";
import {
    AuthProvider as ROCPAuthProvider,
    AuthContext,
} from "react-oauth2-code-pkce";

const IDP_PREFIX = process.env.REACT_APP_MO_IDP_PREFIX;
const REDIRECT_URL = `${window.location.origin}/auth/callback`;
const CLIENT_ID = process.env.REACT_APP_MO_CLIENT_ID;
const CLIENT_SECRET = process.env.REACT_APP_MO_CLIENT_SECRET;

const authConfig = {
    decodeToken: false,
    autoLogin: true,
    clientId: CLIENT_ID,
    authorizationEndpoint: `https://${IDP_PREFIX}.xecurify.com/moas/idp/openidsso`,
    tokenEndpoint: `https://${IDP_PREFIX}.xecurify.com/moas/rest/oauth/token`,
    redirectUri: REDIRECT_URL,
    logoutEndpoint: `https://${IDP_PREFIX}.xecurify.com/moas/idp/oidc/logout`,
    scope: "openid",
    onRefreshTokenExpire: (event) =>
        window.confirm(
            "Session expired. Refresh page to continue using the site?",
        ) && event.login(),
    preLogin: (params) => console.log({ preLogin: params }),
    postLogin: (params) => console.log({ postLogin: params }),
    extraAuthParams: {
        client_secret: CLIENT_SECRET,
    },
};

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    return (
        <ROCPAuthProvider authConfig={authConfig}>{children}</ROCPAuthProvider>
    );
}
