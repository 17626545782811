import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { Button, Input } from "reactstrap";
import { toast } from "react-toastify";
import {
    generateAuthToken,
    getAuthToken,
    getPullClientsMetadataFilesUrlPrefix,
} from "../../../api";
import { OBScreensContext } from "../../../contexts/OBScreensContext";
import {
    ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS,
    getMappingFileName,
    GRAPHQL_RESERVATION_URLS,
    GRAPHQL_RESERVATION_URLS_PROD,
    GRAPHQL_SEARCH_URL,
    GRAPHQL_SEARCH_URL_PROD,
    INSOMNIA_FILES_URL,
    STOPLIGHT_URL,
} from "../../../constants";

const DashboardFiles = () => {
    const {
        setIsLoading,
        clientOnboardingScreen: { onboardingScreen },
    } = useContext(OBScreensContext);
    const [authToken, setAuthToken] = useState("");
    const [hoveredElem, setHoveredElem] = useState("");
    const [mappingFilesPrefix, setMappingFilesPrefix] = useState("");

    const requestBody = useMemo(() => {
        return {
            env:
                ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[4] === onboardingScreen
                    ? "sandbox"
                    : "prod",
        };
    }, [onboardingScreen]);

    useEffect(() => {
        setIsLoading(true);
        Promise.all([
            getAuthToken(requestBody),
            getPullClientsMetadataFilesUrlPrefix(requestBody),
        ]).then(
            ([res1, res2]) => {
                setIsLoading(false);
                setAuthToken(res1?.data);
                setMappingFilesPrefix(res2?.data);
            },
            () => {
                setIsLoading(false);
                toast.error("Failed to get token");
            },
        );
    }, [setIsLoading, requestBody]);

    const generateToken = useCallback(() => {
        setIsLoading(true);
        generateAuthToken(requestBody).then(
            (res) => {
                setAuthToken(res?.data);
                setIsLoading(false);
            },
            () => {
                setIsLoading(false);
                toast.error("Failed to generate token");
            },
        );
    }, [setIsLoading, requestBody]);

    const handleHover = (elem) => {
        setHoveredElem(elem);
    };

    const handleMouseLeave = () => {
        setHoveredElem("");
    };
    const copyToClipboard = () => {
        navigator.clipboard.writeText(authToken).then(() => {
            toast.success("Copied token to clipboard!");
        });
    };

    const fileType = useMemo(() => {
        return ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[4] === onboardingScreen
            ? "Sandbox"
            : "";
    }, [onboardingScreen]);

    const getSearchUrl = useMemo(() => {
        if (onboardingScreen !== ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[12]) {
            return GRAPHQL_SEARCH_URL;
        }
        return GRAPHQL_SEARCH_URL_PROD;
    }, [onboardingScreen]);

    const getReservationUrl = useCallback(
        (type) => {
            if (
                onboardingScreen !== ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[12]
            ) {
                return GRAPHQL_RESERVATION_URLS[type];
            }
            return GRAPHQL_RESERVATION_URLS_PROD[type];
        },
        [onboardingScreen],
    );

    return (
        <div className="dashboard-mapping-files">
            <div
                onMouseEnter={() => handleHover("MAPPING")}
                onMouseLeave={() => handleMouseLeave()}
            >
                <div className="mapping-file-wrapper-div">
                    <i className="fa fa-paper-plane mapping-file-fa" />
                    <div className="mapping-file-wrapper-name">
                        {fileType ? `${fileType} m` : "M"}apping files
                    </div>
                </div>
                {hoveredElem === "MAPPING" ? (
                    <div className="mapping-files-details-div">
                        <p className="mapping-files-details-header">
                            These are your property mapping files.
                        </p>
                        {fileType === "Sandbox" ? (
                            <p className="mapping-files-details-desc">
                                Please use these files to make sure you are
                                mapped to the sandbox properties you need to
                                certify.
                            </p>
                        ) : (
                            <p className="mapping-files-details-desc">
                                Please use these files to make sure you are
                                mapped to all the properties available to you.
                            </p>
                        )}

                        <p className="mapping-files-details-desc">
                            <i className="fa fa-paperclip" />
                            <a
                                href={getMappingFileName(
                                    mappingFilesPrefix,
                                    "HTR_parent_region_list.csv",
                                )}
                            >
                                Parent Region List
                            </a>
                        </p>
                        <p className="mapping-files-details-desc">
                            <i className="fa fa-paperclip" />
                            <a
                                href={getMappingFileName(
                                    mappingFilesPrefix,
                                    "HTR_property_static_data.csv",
                                )}
                            >
                                Property Static Data
                            </a>
                        </p>
                        <p className="mapping-files-details-desc">
                            <i className="fa fa-paperclip" />
                            <a
                                href={getMappingFileName(
                                    mappingFilesPrefix,
                                    "HTR_rooms.csv",
                                )}
                            >
                                Rooms
                            </a>
                        </p>
                        <p className="mapping-files-details-desc">
                            <i className="fa fa-paperclip" />
                            <a
                                href={getMappingFileName(
                                    mappingFilesPrefix,
                                    "HTR_meal_plan_options.csv",
                                )}
                            >
                                Mealplan Options
                            </a>
                        </p>
                    </div>
                ) : (
                    <div className="mapping-files-gap" />
                )}
            </div>

            {(onboardingScreen === ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[4] ||
                onboardingScreen ===
                    ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[12]) && (
                <div
                    onMouseEnter={() => handleHover("DEV_DOCS")}
                    onMouseLeave={() => handleMouseLeave()}
                >
                    <div className="mapping-file-wrapper-div">
                        <i className="fa fa-file-text mapping-file-fa" />
                        <span className="mapping-file-wrapper-name">
                            {onboardingScreen ===
                            ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[4]
                                ? "Dev docs"
                                : "Production Endpoints"}
                        </span>
                    </div>

                    {hoveredElem === "DEV_DOCS" ? (
                        <div className="mapping-files-details-div">
                            <p className="mapping-files-details-header">
                                These are your{" "}
                                {onboardingScreen ===
                                ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[4]
                                    ? "development documents."
                                    : "production endpoints."}
                            </p>
                            <div className="mapping-files-details-desc">
                                {onboardingScreen ===
                                    ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[4] && (
                                    <>
                                        <p>
                                            <a
                                                target="_blank"
                                                rel="noreferrer"
                                                href={STOPLIGHT_URL}
                                            >
                                                Stoplight Docs
                                            </a>
                                        </p>
                                        <p>
                                            <a
                                                target="_blank"
                                                rel="noreferrer"
                                                href={INSOMNIA_FILES_URL}
                                            >
                                                Insomnia Files
                                            </a>
                                        </p>
                                    </>
                                )}

                                <p>
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={getSearchUrl}
                                    >
                                        Search -{" "}
                                        <span className="file-url">
                                            {getSearchUrl}
                                        </span>
                                    </a>
                                </p>
                                <p>
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={getReservationUrl("QUOTE")}
                                    >
                                        Quote -{" "}
                                        <span className="file-url">
                                            {getReservationUrl("QUOTE")}
                                        </span>
                                    </a>
                                </p>
                                <p>
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={getReservationUrl("BOOK")}
                                    >
                                        Book, Modify and Cancel -{" "}
                                        <span className="file-url">
                                            {getReservationUrl("BOOK")}
                                        </span>
                                    </a>
                                </p>
                                <p>
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={getReservationUrl("RESERVATION")}
                                    >
                                        Get reservation -{" "}
                                        <span className="file-url">
                                            {getReservationUrl("RESERVATION")}
                                        </span>
                                    </a>
                                </p>
                            </div>
                        </div>
                    ) : (
                        <div className="mapping-files-gap" />
                    )}
                </div>
            )}

            {onboardingScreen !== ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[7] &&
                onboardingScreen !==
                    ALL_OB_QUESTIONNAIRE_DASHBOARD_SCREENS[8] && (
                    <div
                        onMouseEnter={() => handleHover("TOKEN")}
                        onMouseLeave={() => handleMouseLeave()}
                    >
                        <div className="mapping-file-wrapper-div">
                            <i className="fa fa-shield mapping-file-fa" />
                            <span className="mapping-file-wrapper-name">
                                Generate {fileType} auth token
                            </span>
                            {hoveredElem === "TOKEN" && (
                                <div className="mapping-files-btn-div">
                                    <Button
                                        type="submit"
                                        className="mapping-files-btn mt-5"
                                        size="lg"
                                        onClick={generateToken}
                                    >
                                        generate token
                                    </Button>
                                </div>
                            )}
                        </div>

                        {hoveredElem === "TOKEN" && (
                            <div className="mapping-files-details-div">
                                <p className="mapping-files-details-header">
                                    All tokens are unique!
                                </p>
                                <p className="mapping-files-details-desc">
                                    Generating a new token replaces the previous
                                    token, please ensure you are using the most
                                    recent token or calls will fail.
                                </p>
                                <div className="token-input-div">
                                    <Input
                                        type="text"
                                        value={authToken}
                                        disabled
                                        className="mapping-files-auth-input"
                                    />
                                    <span
                                        className="token-copy-text"
                                        onClick={copyToClipboard}
                                    >
                                        <i className="fa fa-clone" />
                                        copy
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>
                )}
        </div>
    );
};

export default DashboardFiles;
