import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import RootLayout from "./pages/Root";
import DashboardPage from "./pages/dashboard/Dashboard";
import DocumentationPage from "./pages/documentation/Documentation";
import LoginPage from "./pages/login/Login";
import { AuthProvider } from "./contexts/AuthContext";
import RequireAuth from "./route-guards/RequireAuth";
import Team from "./pages/team/Team";
import { Callback } from "./pages/login/Callback";

import Unauthorized from "./pages/unauthorized/Unauthorized";
import Profile from "./pages/profile/Profile";
import Logout from "./pages/login/Logout";
import Questionnaire from "./components/cert-tabs/Questionnaire";
import React from "react";
import { CLIENT_ACCOUNT_DETAILS_FORM } from "./constants";

function App() {
    return (
        <AuthProvider>
            <Router>
                <Routes>
                    <Route path="/auth" element={<LoginPage />} />
                    <Route path="/logout" element={<Logout />} />
                    <Route path="/auth/callback" element={<Callback />} />

                    <Route path="/" element={<RootLayout />}>
                        <Route element={<RequireAuth page="dashboard" />}>
                            <Route index element={<DashboardPage />} />
                        </Route>
                        <Route element={<RequireAuth page="documentation" />}>
                            <Route
                                path="docs"
                                element={<DocumentationPage />}
                            />
                        </Route>
                        <Route element={<RequireAuth page="documentation" />}>
                            <Route
                                path="client-details"
                                element={
                                    <Questionnaire
                                        key={`dashboard-questionnaire-${CLIENT_ACCOUNT_DETAILS_FORM}`}
                                        questionnaireType={
                                            CLIENT_ACCOUNT_DETAILS_FORM
                                        }
                                    />
                                }
                            />
                        </Route>
                        <Route element={<RequireAuth page="teamMembers" />}>
                            <Route path="team" element={<Team />} />
                        </Route>
                        <Route element={<RequireAuth />}>
                            <Route path="profile" element={<Profile />} />
                        </Route>
                        <Route
                            path="/unauthorized"
                            element={<Unauthorized />}
                        />
                    </Route>
                </Routes>
            </Router>
        </AuthProvider>
    );
}

export default App;
