import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

const CustomPopUpWindow = (props) => {
    let {
        toggle,
        isOpen,
        header,
        body,
        extraButtons,
        hideFooter,
        className,
        closeButtonText,
        hideHeader,
        addButtonsToHeader,
        footerClassName,
    } = props;

    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            backdrop
            className={className || ""}
        >
            {!hideHeader && (
                <ModalHeader toggle={toggle} className="">
                    <div>
                        {header}
                        {addButtonsToHeader && (
                            <>
                                {!!extraButtons && extraButtons}
                                <Button onClick={toggle}>
                                    {closeButtonText || "Close"}
                                </Button>
                            </>
                        )}
                    </div>
                </ModalHeader>
            )}
            {body && <ModalBody>{body}</ModalBody>}
            {!hideFooter && (
                <ModalFooter className={footerClassName}>
                    <Button onClick={toggle}>
                        {closeButtonText || "Close"}
                    </Button>
                    {!!extraButtons && extraButtons}
                </ModalFooter>
            )}
        </Modal>
    );
};

export default CustomPopUpWindow;
